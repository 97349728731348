/** @jsx h */
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import sumBy from 'lodash/sumBy';
import without from 'lodash/without';
import { h, Component, Fragment, render } from 'preact';
import queue from 'async/queue';

import loadDictionary from '~lib/dictionaries/loadDictionary';
import parseQuery from '~lib/parse-query';
import ExplorePaymentsForm from '~ui/components/explorePaymentsForm';
import RequestMoreInfoForm from '~ui/components/requestMoreInfoForm';
import PrivateOffersForm from '~ui/components/privateOffers';
import ScheduleTestDriveForm from '~ui/components/scheduleTestDriveForm';
import SharedDumbUI from '~ui/mainUI/sharedDumbUI';
import VehicleAutofiCTAs from '~ui/components/vehicleAutofiCTAs';
import { AmplitudeLabelComponents, CallbackState, OEMs, OfferType, Pathways, VehicleStatus, View } from '~lib/enum';
import { getEstimate } from '~ui/estimator';
import * as utils from '~ui/utils';
import {
	trackAmplitudeEvent,
	trackShiftDigitalEvent,
	trackJdPowerEvent,
	trackAdobePixelEvent,
} from '~ui/utils/analytics';
import consumerSession from '~ui/utils/consumerSession';

import { PathwaysApiUi } from '~ui/api-based';
import { embedGoogleAnalytics4 } from '~ui/utils/googleAnalytics';
import DriveTogetherOrchestrator from '~ui/components/driveTogetherOrchestrator';

const MAX_CONCURRENT_ESTIMATES = 4;

class MainUiComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			apiKey: null,
			applicant: utils.makeApplicant({
				comments: null,
				contactData: null,
				dealer: props.autofiData.dealer,
			}),
			autofiVehiclelessCtaMap: {},
			currentPathway: null,
			currentVehicle: null,
			currentView: null,
			customerReferenceId: null,
			dealerCodeOverride: null,
			dealerDiscount: null,
			estimatesByVin: {},
			estimatorErrorsByVin: {},
			hasShownAutoPathway: false,
			iframeIsLoading: false,
			iframePathway: null,
			iframeSrc: '',
			loanAppId: '',
			loanAppRequestInProgress: false,
			OEMDiscount: null,
			oemLeadSource: null,
			onVehicleDataQueues: {},
			pathwaysApiCallback: null,
			pathwayTitles: { currentSubTitle: '', currentTitle: '' },
			privateOffers: [],
			privateOffersStartPageIndex: 0,
			shiftDigitalSessionId: null,
			userIsInSession: false,
			vehiclesByVin: {},
		};

		this.ctaTypeClickHandlerMap = {
			creditApp: this.handleCreditAppClick,
			deposit: this.handleDepositPaymentClick,
			driveTogether: this.handleDriveTogetherClick,
			prequalify: this.handlePrequalifyClick,
			privateOffers: this.handlePrivateOffersClick,
			standAloneCreditApp: this.handleStandAloneCreditAppClick,
			startApplication: this.handleExplorePaymentsClick,
			requestMoreInfo: this.handleRequestMoreInfoClick,
			testDrive: this.handleScheduleTestDriveClick,
			tradeInWithVin: this.handleTradeInClick,
		};
	}

	_estimatorQueue = null;

	estimateVinsInProgress = new Set();

	get estimatorQueue() {
		if (!this._estimatorQueue) {
			this._estimatorQueue = queue(this.callEstimator, MAX_CONCURRENT_ESTIMATES);
		}
		return this._estimatorQueue;
	}

	componentDidMount() {
		this.trackAmplitudeEventWithCurrentData({ action: AmplitudeLabelComponents.Action.Load });
		this.getUserSession();
		window.addEventListener('message', this.messageEvent);
		this.takeOverDealerVehiclelessCTAs();
	}

	componentDidUpdate = (prevProps, prevState) => {
		const { dealerVehiclelessCtaMap, pageType } = this.props;
		const { currentVehicle, currentView, onVehicleDataQueues, vehiclesByVin } = this.state;
		// currentVehicle has vehicle shown on pages using pathways API.
		// vehiclesByVin has all vehicles on VDPs and SRPs, and is empty on other pages.
		// TODO: make this more consistent.

		[currentVehicle, ...Object.values(vehiclesByVin)]
			.filter((v) => v && v.vin in onVehicleDataQueues && v.status !== VehicleStatus.Pending)
			.forEach((vehicle) =>
				onVehicleDataQueues[vehicle.vin].forEach((resolve) => {
					resolve(vehicle);

					this.setState((prevState) => ({
						onVehicleDataQueues: {
							...prevState.onVehicleDataQueues,
							[vehicle.vin]: without(prevState.onVehicleDataQueues[vehicle.vin], resolve),
						},
					}));
				})
			);

		const modalIsOpen = Boolean(currentView);
		const modalWasOpen = Boolean(prevState.currentView);
		const shouldHideOverlays = ['details', 'listings'].includes(pageType);

		if (shouldHideOverlays) {
			if (modalIsOpen && !modalWasOpen) {
				utils.hideOverlays();
			} else if (!modalIsOpen && modalWasOpen) {
				utils.showOverlays();
			}
		}

		if (pageType === 'details') {
			const vehicleChanged = get(currentVehicle, 'vin') !== get(prevState.currentVehicle, 'vin');
			if (vehicleChanged && currentVehicle) {
				this.hideNodes();
			}
		}

		if (dealerVehiclelessCtaMap !== prevProps.dealerVehiclelessCtaMap) {
			this.takeOverDealerVehiclelessCTAs();
		}

		if (!this.state.hasShownAutoPathway) {
			this.loadAutoPathway();
		}
	};

	componentWillUnmount() {
		window.removeEventListener('message', this.messageEvent);
	}

	/**
	 *
	 * @param {string} errorMessage Error message to pass to the `callback` pathways if set.
	 * @param {error} object Optional. Pass if you want to append it to the callback
	 * payload.
	 */
	invokePathwaysCallback = (errorMessage, error) => {
		this.state.pathwaysApiCallback?.({
			type: CallbackState.Error,
			message: errorMessage,
			...error,
		});
	};

	/**
	 * Sets the current vehicle for the UI in its state
	 *
	 * @param {Object} vehicle
	 * @param {function} callback
	 */
	setCurrentVehicle = (vehicle, callback) =>
		this.setState((prevState) => {
			const prevVehicle = prevState.currentVehicle;
			const vinChanged = get(prevVehicle, 'vin') !== get(vehicle, 'vin');
			const responseWasPending = get(prevVehicle, 'status') === VehicleStatus.Pending;
			if (vinChanged || responseWasPending) {
				// reset the iframeSrc if we're really updating the vehicle,
				// so the loanapp will be reloaded in SRP
				return { currentVehicle: vehicle, iframePathway: null, iframeSrc: '' };
			}

			return prevState;
		}, callback);

	setAutofiVehiclelessCtaMap = (autofiVehiclelessCtaMap) => this.setState({ autofiVehiclelessCtaMap });

	/**
	 * Adds vehicles for the UI in its state
	 * Called everytime new vehicles are added
	 *
	 * @param {Object[]} vehicles
	 */
	addVehicles = (vehicles) =>
		new Promise((resolve) => {
			const { dealer: currentDealer } = this.props.autofiData;

			const vehiclesToAdd = vehicles.filter((vehicle) =>
				utils.shouldRenderForVehicle(vehicle, currentDealer, window.location.href)
			);

			if (!vehiclesToAdd.length) {
				resolve();
				return;
			}

			vehiclesToAdd.forEach((vehicle) => this.takeOverDealerVehicleCTAs(vehicle));

			const newVehiclesByVin = keyBy(vehiclesToAdd, 'vin');

			this.setState(
				(prevState) => ({
					vehiclesByVin: { ...prevState.vehiclesByVin, ...newVehiclesByVin },
				}),
				this.queueMissingEstimates
			);

			const currentVin = this.state.currentVehicle?.vin;
			if (currentVin in newVehiclesByVin) {
				this.setCurrentVehicle(newVehiclesByVin[currentVin], resolve);
			} else {
				resolve();
			}
		});

	getVehicleByVin = (vin) => {
		// currentVehicle has vehicle shown on pages using pathways API.
		// vehiclesByVin has all vehicles on VDPs and SRPs, and is empty on other pages.
		// TODO: make this more consistent.
		const { currentVehicle, vehiclesByVin } = this.state;
		return currentVehicle?.vin === vin ? currentVehicle : vehiclesByVin[vin];
	};

	setApplicant = (applicant, callback) => {
		if (applicant) {
			this.setState({ applicant }, callback);
		} else if (callback) {
			callback();
		}
	};

	/**
	 * Sets Events on window
	 * communication between the iframe and Panda
	 */
	messageEvent = (event) => {
		if (['autofi-hide', 'autofi-close'].includes(event.data)) {
			this.closeModal();
		}
	};

	/**
	 * Binds CTAs events for one vehicle
	 *
	 * @param {Object} vehicle
	 */
	takeOverDealerVehicleCTAs = (vehicle) => {
		const { dealerCTAs } = vehicle.ui;

		if (dealerCTAs) {
			[
				['deposit', this.handleDepositPaymentClick],
				['driveTogether', this.handleDriveTogetherClick],
				['prequalify', this.handlePrequalifyClick],
				['privateOffers', this.handlePrivateOffersClick],
				['requestMoreInfo', this.handleRequestMoreInfoClick],
				['startApplication', this.handleExplorePaymentsClick],
				['testDrive', this.handleScheduleTestDriveClick],
				['tradeInWithVin', this.handleTradeInClick],
			].forEach(([ctaType, handler]) => {
				utils.takeOverDealerCTAs(
					dealerCTAs[ctaType] || [],
					(clickEvent) => handler(vehicle.vin, { clickEvent }),
					ctaType,
					vehicle.vin
				);
			});
		}
	};

	onModalIframeLoad = () => this.setState({ iframeIsLoading: false });

	/**
	 * Shows deal maker.
	 * Creates a new loanapp if it has not been already loaded.
	 *
	 * @param {string} pathway
	 * @param {Object} vehicle
	 * @param {function} callback
	 */
	showDealMaker = (pathway, vehicle, callback = () => {}) => {
		const { pageType, autofiData } = this.props;
		const {
			apiKey,
			applicant,
			customerReferenceId,
			dealerCodeOverride,
			iframePathway,
			oemLeadSource,
			pathwayTitles,
			preferences,
			shiftDigitalSessionId,
			trackId,
		} = this.state;
		const { sessionData } = autofiData;

		// Don't show iframe container for deposit pathway.
		if (pathway !== Pathways.Deposit) {
			this.setState({ currentView: View.LOANAPP });
		}

		if (iframePathway && iframePathway !== Pathways.StandAloneCreditApp) {
			// the loanApp iframeSrc has already been retrieved
			// No need to create a new loanApp
			this.setState({ iframePathway: pathway }, callback);
			return;
		}

		this.setState({ iframeIsLoading: true, iframeSrc: '', loanAppRequestInProgress: true });

		utils.createLoanApp(
			applicant,
			vehicle,
			pageType,
			{
				apiKey,
				customerReferenceId,
				dealerCodeOverride,
				oemLeadSource,
				pathway,
				pathwayTitles,
				preferences,
				shiftDigitalSessionId,
				trackId,
			},
			autofiData,
			(err, response) => {
				if (this.state.loanAppRequestInProgress) {
					if (err) {
						callback(err, response);
						this.invokePathwaysCallback(err);
						// `err` contains the actual error now - but we'll hide it from the user and show it
						// as a callback event on the apiPathways callback argument. This way we maintain
						// behavior backwards compatibility.
						alert('Sorry! Something went wrong on our end. Please try again later.');
						return;
					}

					const iframeSrc = utils.getDealMakerIframeUrl(response.url, pathway, sessionData);
					this.setState(
						{
							loanAppId: response.id,
							loanAppRequestInProgress: false,
							// For deposit pathway, we don't show the iframe. callback will redirect to
							// Stripe's checkout page, which will redirect to deal maker.
							...(pathway !== Pathways.Deposit && { iframePathway: pathway, iframeSrc }),
						},
						() => callback(err, response)
					);
				} else {
					callback(err, response);
				}
			}
		);
	};

	showStandAloneCreditApp = () => {
		const { autofiData } = this.props;

		this.setState({
			currentView: View.LOANAPP,
			iframeIsLoading: true,
			iframeSrc: '',
			loanAppRequestInProgress: true,
		});

		utils.createStandAloneCreditApplication(autofiData, (err, response) => {
			if (this.state.loanAppRequestInProgress) {
				if (err) {
					alert(err);
					return;
				}
				this.setState({
					iframePathway: Pathways.StandAloneCreditApp,
					iframeSrc: response.iframeSrc,
					loanAppRequestInProgress: false,
				});
			}
		});
	};

	showThankYouMessage = (pathway, completeVehicle, callback = () => {}) => {
		const { autofiData, pageType } = this.props;
		const { apiKey, applicant, customerReferenceId, dealerCodeOverride, oemLeadSource, preferences, trackId } =
			this.state;
		this.setState({ currentView: View.THANK_YOU, iframeIsLoading: false }, callback);

		utils.createInvalidVehicleLead(
			applicant,
			completeVehicle,
			pageType,
			{
				apiKey,
				customerReferenceId,
				dealerCodeOverride,
				oemLeadSource,
				pathway,
				preferences,
				trackId,
			},
			autofiData
		);
	};

	/**
	 * Closes current modal (forms or loanapp)
	 */
	closeModal = () => {
		this.setState({
			apiKey: null,
			currentPathway: null,
			currentView: null,
			customerReferenceId: null,
			dealerCodeOverride: null,
			iframeIsLoading: false,
			loanAppRequestInProgress: false,
			oemLeadSource: null,
		});

		this.state.pathwaysApiCallback?.({ type: CallbackState.Close });
	};

	/**
	 * If vehicle has valid pricing and offers, show deal maker and create a
	 * new loanApp if it hasn't already been initialized. If vehicle does not
	 * have valid pricing and offers, show thank you modal and create a new
	 * customer in db.
	 *
	 * @param {string} pathway
	 * @param {string} vin
	 * @param {() => void} [callback]
	 */
	handleLead = async (pathway, vin, callback = () => {}) => {
		const completeVehicle = await this.getCompleteVehicleByVin(vin);

		if (utils.shouldShowDealMaker(completeVehicle, window.location.href)) {
			this.showDealMaker(pathway, completeVehicle, callback);
		} else {
			this.showThankYouMessage(pathway, completeVehicle, callback);
		}
	};

	trackAmplitudeEventWithCurrentData = ({ action, event, label, opts, pathway, vehicle }) => {
		const { autofiData, pageType } = this.props;
		const { estimatesByVin, trackId } = this.state;
		const estimate = estimatesByVin[vehicle?.vin];
		// TODO: find a way to test this behavior with real events. JSDom doesn't support
		// innerText. See https://github.com/jsdom/jsdom/issues/1245
		const ctaText = event?.currentTarget?.innerText;
		const { dealer, sessionData } = autofiData;
		const { isInStore } = sessionData || {};

		trackAmplitudeEvent({
			action,
			ctaText,
			dealer,
			estimate,
			isInStore,
			label,
			opts,
			pageType,
			pathway,
			trackId,
			vehicle,
		});
	};

	/**
	 * Confirms pathway is enabled for a dealer
	 *
	 * @param {string} pathway
	 * @returns {boolean}
	 */
	isPathwayEnabled = (pathway) => {
		const { autofiData, pageType } = this.props;
		const { dealer, sessionData } = autofiData;
		const { websiteSettings } = dealer;
		return utils.isPathwayEnabled(pathway, websiteSettings, pageType, sessionData);
	};

	/**
	 * Gets a user session and sets user info in state
	 */
	getUserSession = () => {
		const { autofiData } = this.props;
		const { dealer } = autofiData;

		if (utils.isAggregator(dealer)) {
			utils.logToConsole(`AutoFi Debugger: Aggregator (${dealer.source}) Request, skipping consumerSession`);
			return;
		}

		consumerSession.getFromDB(autofiData, (userInfo) => {
			const { applicant } = this.state;
			const { trackId, preferences } = userInfo || {};

			this.setState({
				applicant: utils.updateApplicant(userInfo, applicant),
				...(preferences ? { preferences } : {}),
				trackId,
				userIsInSession: Boolean(userInfo),
			});
		});
	};

	loadAutoPathway = () => {
		const { autofiData, pageType } = this.props;

		if (pageType === 'details') {
			const vehicle = Object.values(this.state.vehiclesByVin)[0];
			if (vehicle) {
				const currentDealer = autofiData.dealer;
				const { autoLoadExplorePayments } = currentDealer.websiteSettings.ui.details;
				if (utils.shouldRenderForVehicle(vehicle, currentDealer, window.location.href)) {
					const normalizedQuery = parseQuery(window.location.href);
					const rawPathway = normalizedQuery['af-pathway'] || (autoLoadExplorePayments ? 'explorePayments' : null);
					if (rawPathway) {
						const ctaType = utils.ctaTypeFromPathwayName(rawPathway);
						const handleClick = this.ctaTypeClickHandlerMap[ctaType];
						if (handleClick) {
							handleClick(vehicle.vin);
							this.setState({ hasShownAutoPathway: true });
						} else {
							utils.errorToConsole(`Unsupported pathway: ${rawPathway}`);
						}
					}
				}
			}
		}
	};

	shouldShowLeadGenForm = (pathway) => {
		if (pathway === 'deposit') {
			return true;
		}
		const { dealer: currentDealer } = this.props.autofiData;
		const { applicant } = this.state;
		const pathwayValidationFn = {
			[Pathways.CreditApp]: () => false,
			[Pathways.DriveTogether]: () => false,
			[Pathways.ExplorePayments]: ExplorePaymentsForm.allRequiredFieldsFilled,
			[Pathways.Prequalify]: () => false,
			[Pathways.PrivateOffers]: PrivateOffersForm.allRequiredFieldsFilled,
			[Pathways.RequestMoreInfo]: RequestMoreInfoForm.allRequiredFieldsFilled,
			[Pathways.TestDrive]: ScheduleTestDriveForm.allRequiredFieldsFilled,
			[Pathways.UnlockPricing]: ExplorePaymentsForm.allRequiredFieldsFilled,
			[Pathways.TradeIn]: () => false,
		}[pathway];
		const requiredFieldsFilled = pathwayValidationFn(currentDealer, applicant);
		return !requiredFieldsFilled && this.isPathwayEnabled(pathway);
	};

	/**
	 * @param {string} pathway
	 * @param {string} vin
	 * @param {Event|string} clickEventOrCtaText
	 * @param {object} opts
	 * @param {[string]} opts.dealerCodeOverride override used by pathways API
	 * @param {[string]} opts.formHeader override used by pathways API
	 * @param {[string]} opts.formSubheader override used by pathways API
	 * @param {[string]} opts.oemLeadSource provider name for the OEM lead
	 *     source, used for aggregators
	 *
	 * clickEventOrCtaText can be an element with a dataset of any custom pathway headers
	 * or the CTA text of whichever button was clicked
	 */
	launchPathway = async (pathway, vin, clickEventOrCtaText, opts = {}) => {
		const { autofiData, pageType } = this.props;
		const { dealer: currentDealer, sessionData } = autofiData;
		const { applicant } = this.state;
		const { apiKey, customerReferenceId, dealerCode: dealerCodeOverride, oemLeadSource } = opts;

		// Setting current vehicle at this point for listings
		const vehicle = this.getVehicleByVin(vin);
		// vehicle may be undefined for pathways with no vehicle, like credit app
		this.setCurrentVehicle(vehicle);

		if (dealerCodeOverride !== this.state.dealerCodeOverride) {
			this.setState({ iframePathway: null, iframeSrc: '' });
		}

		this.setState({
			apiKey,
			currentPathway: pathway,
			customerReferenceId,
			dealerCodeOverride,
			oemLeadSource,
		});

		const pathwayWasLaunchedByCta = pageType === 'api' || clickEventOrCtaText;
		if (pathwayWasLaunchedByCta) {
			// We may not have all the vehicle data at this point. We should not predicate
			// tracking Pathway: Start events on calls to hippo. Vehicle data is attached once
			// we have it.
			this.trackAmplitudeEventWithCurrentData({
				action: AmplitudeLabelComponents.Action.Click,
				event: clickEventOrCtaText,
				pathway,
				vehicle: vehicle || { vin },
			});
		}

		trackShiftDigitalEvent('drImpression', vehicle, currentDealer, sessionData, applicant, pageType);
		trackShiftDigitalEvent('drInitialClick', vehicle, currentDealer, sessionData, applicant, pageType);

		if (pathway === Pathways.StandAloneCreditApp) {
			trackShiftDigitalEvent('drCreditAppShown', vehicle, currentDealer, sessionData, applicant, pageType);
			this.showStandAloneCreditApp();
		} else if (this.shouldShowLeadGenForm(pathway)) {
			const currentView = {
				[Pathways.CreditApp]: View.CREDIT_APP,
				[Pathways.Deposit]: View.DEPOSIT,
				[Pathways.DriveTogether]: View.DRIVE_TOGETHER,
				[Pathways.ExplorePayments]: View.EXPLORE_PAYMENTS,
				[Pathways.Prequalify]: View.PREQUALIFY,
				[Pathways.PrivateOffers]: View.PRIVATE_OFFERS,
				[Pathways.RequestMoreInfo]: View.REQUEST_MORE_INFO,
				[Pathways.TestDrive]: View.TEST_DRIVE,
				[Pathways.TradeIn]: View.LOANAPP,
				[Pathways.UnlockPricing]: View.EXPLORE_PAYMENTS,
			}[pathway];

			// For Pathways API consumers, clickEventOrCtaText will be undefined or a
			// string, not an event.
			const eventTarget = get(clickEventOrCtaText, 'currentTarget');
			const titles = utils.getPathwayHeaderTexts(currentDealer, pathway, eventTarget, opts);

			trackShiftDigitalEvent('drLeadFormShown', vehicle, currentDealer, sessionData, applicant, pageType);
			if (pathway === Pathways.TestDrive) {
				trackShiftDigitalEvent('drApptSchedShown', vehicle, currentDealer, sessionData, applicant, pageType);
			}

			if (pathway === Pathways.TradeIn) {
				this.setState({ pathwayTitles: titles });
				trackShiftDigitalEvent('drTradeInShown', vehicle, currentDealer, sessionData, applicant, pageType);

				// Will directly land on trade-in flow on opened DM
				this.handleLead(pathway, vin);
			} else {
				this.setState({ currentView, iframePathway: null, iframeSrc: '', pathwayTitles: titles });
			}
			this.trackAmplitudePathwayStartEvents(pathway, vehicle, clickEventOrCtaText);
		} else {
			const completeVehicle = await this.getCompleteVehicleByVin(vin);

			if (pathway === Pathways.PrivateOffers) {
				this.setState({ iframeIsLoading: true, iframePathway: null, iframeSrc: '' });
				await this.handlePrivateOffersEstimate(applicant, completeVehicle);
			}

			this.handleLead(pathway, vin, (_err, response) => {
				if (!pathwayWasLaunchedByCta && response) {
					this.trackAmplitudeEventWithCurrentData({
						label: 'Dealmaker: home: autoload',
						opts: { loanAppId: response.id },
						vehicle: completeVehicle,
					});
				}
			});
		}
	};

	trackAmplitudePathwayStartEvents = (pathway, vehicle, clickEventOrCtaText) => {
		const { pageType } = this.props;
		const { Load } = AmplitudeLabelComponents.Action;
		const { PathwayCreditEstimator, PathwayStart, PathwayStartAutoload } = AmplitudeLabelComponents.Label;

		if (pathway === Pathways.CreditApp) {
			this.trackAmplitudeEventWithCurrentData({
				action: Load,
				label: PathwayCreditEstimator,
				opts: { step: AmplitudeLabelComponents.Steps.PromoScreen },
				pathway,
			});
		}

		if (pathway !== Pathways.TradeIn) {
			// TODO: Always pass pathway in the following events for consistency.
			// See https://autofi.atlassian.net/browse/CS-1629

			const pathwayWasLaunchedByCta = pageType === 'api' || clickEventOrCtaText;

			if (!pathwayWasLaunchedByCta) {
				this.trackAmplitudeEventWithCurrentData({
					action: Load,
					label: pathway === Pathways.Deposit ? 'Pathway: deposit: autoload' : PathwayStartAutoload,
					...(pathway !== Pathways.Deposit && { pathway }),
					...(pathway !== Pathways.CreditApp && { vehicle }),
				});
			}

			this.trackAmplitudeEventWithCurrentData({
				action: Load,
				label: pathway === Pathways.Deposit ? 'Pathway: deposit: load' : PathwayStart,
				...(pathway !== Pathways.Deposit && { pathway }),
				...(pathway !== Pathways.CreditApp && { vehicle }),
			});
		}
	};

	handleDepositPaymentClick = (vin, opts) => {
		const { clickEvent, ctaText } = opts;
		const vehicle = this.getVehicleByVin(vin);
		const { dealer: currentDealer } = this.props.autofiData;
		invoke(clickEvent, 'preventDefault');
		invoke(clickEvent, 'stopImmediatePropagation');
		trackJdPowerEvent('DRS-Start', vehicle, currentDealer);
		this.launchPathway(Pathways.Deposit, vin, clickEvent || ctaText, opts);
	};

	handleDepositPaymentSubmit = (applicant, callback = () => {}) => {
		const { currentVehicle } = this.state;

		// TODO: track Adobe event
		this.setState({ applicant });
		this.handleLead(Pathways.Deposit, currentVehicle.vin, callback);
	};

	/**
	 * Click on Explore Payments Button
	 *
	 * @param {String} vin
	 * @param {Object} opts
	 * @param {ClickEvent} opts.clickEvent
	 * @param {ClickEvent} opts.ctaText
	 * @param {String} opts.apiKey override used by pathways API
	 * @param {String} opts.ctaText override used by pathways API
	 * @param {String} opts.customerReferenceId override used by pathways API
	 * @param {String} opts.dealerCode override used by pathways API
	 * @param {String} opts.formHeader override used by pathways API
	 * @param {String} opts.formSubheader override used by pathways API
	 * @param {String} opts.oemLeadSource override used by pathways API
	 */
	handleExplorePaymentsClick = (vin, opts = {}) => {
		const { clickEvent, ctaText } = opts;
		const { dealer: currentDealer } = this.props.autofiData;
		invoke(clickEvent, 'preventDefault');
		invoke(clickEvent, 'stopImmediatePropagation');

		trackAdobePixelEvent('formStart', 'explorePaymentsLead');

		// vehicle only contains a vin and pending status when using pathways API
		const vehicle = this.getVehicleByVin(vin);
		trackJdPowerEvent('DRS-Start', vehicle, currentDealer);
		const isLockedPrice = utils.lockedPricingIsEnabled(currentDealer, vehicle);
		const pathway = isLockedPrice ? Pathways.UnlockPricing : Pathways.ExplorePayments;
		this.launchPathway(pathway, vin, clickEvent || ctaText, opts);
	};

	handleExplorePaymentsSubmit = (applicant, callback = () => {}) => {
		const { dealer: currentDealer } = this.props.autofiData;
		const { currentVehicle } = this.state;
		trackAdobePixelEvent('formSubmit', 'explorePaymentsLead');

		const pathway = utils.lockedPricingIsEnabled(currentDealer, currentVehicle)
			? Pathways.UnlockPricing
			: Pathways.ExplorePayments;

		this.setState({ applicant });
		this.handleLead(pathway, currentVehicle.vin, callback);
	};

	/**
	 * Renders the AutoFi Prequalify Form
	 * @param {String} vin - vehicle vin
	 * @param {Object} opts - pathway options
	 */
	handlePrequalifyClick = (vin, opts = {}) => {
		const { dealer: currentDealer, launchDarklyFeatureFlags } = this.props.autofiData;
		const { prequalifyPathway } = launchDarklyFeatureFlags;
		const { code } = currentDealer;

		const origin = utils.getPrequalifyOrigin();
		const postMessageTarget = window.location.href;
		const queryParams = new URLSearchParams({ dealerCode: code, vin, postMessageTarget });

		if (prequalifyPathway && origin) {
			this.setState({
				currentPathway: Pathways.Prequalify,
				currentView: View.PREQUALIFY,
				iframeIsLoading: true,
				iframePathway: Pathways.Prequalify,
				iframeSrc: `${origin}?${queryParams}`,
			});
		}
	};

	/**
	 * Renders the Santander Drive Together Form
	 * @param {String} vin - vehicle vin
	 * @param {Object} opts - pathway options
	 */
	handleDriveTogetherClick = (vin, opts = {}) => {
		const { autofiData, pageType } = this.props;
		const { dealer, driveDealer, launchDarklyFeatureFlags } = autofiData;

		const { code: afDealerCode } = dealer;
		const { code } = driveDealer;

		const { driveTogether } = launchDarklyFeatureFlags;
		const origin = utils.getDriveTogetherOrigin();

		const queryParams = new URLSearchParams({ pageType, afDealerCode });

		if (code && driveTogether && origin && vin) {
			this.setState({
				currentPathway: Pathways.DriveTogether,
				currentView: View.DRIVE_TOGETHER,
				iframeIsLoading: true,
				iframePathway: Pathways.DriveTogether,
				iframeSrc: `${origin}/drive-together/${code}/${vin}?${queryParams}`,
			});
		}
	};

	/**
	 * @param {String} loanApplicationId
	 * @param {string} dealMakerPanel
	 */
	handleLoadLoanApplication = async (loanApplicationId, dealMakerPanel) => {
		const { autofiData } = this.props;
		this.setState({
			currentView: null,
			iframeIsLoading: true,
			iframeSrc: '',
			loanAppRequestInProgress: true,
		});

		try {
			const response = await utils.fetchLoanApplication(loanApplicationId, dealMakerPanel, autofiData);
			this.setState({
				currentView: View.LOANAPP,
				iframeIsLoading: false,
				loanAppRequestInProgress: false,
				iframePathway: Pathways.ExplorePayments,
				iframeSrc: response.url,
				loanAppId: loanApplicationId,
			});
		} catch (err) {
			this.invokePathwaysCallback(`The loanApplcation with id ${loanApplicationId} failed to load`, err);
			this.setState({ iframeIsLoading: false, loanAppRequestInProgress: false });
		}
	};

	/**
	 * Click on Request More Info Button
	 *
	 * @param {string} vin
	 * @param {object} opts
	 * @param {ClickEvent} opts.clickEvent
	 * @param {ClickEvent} opts.ctaText
	 * @param {[string]} opts.formHeader override used by pathways API
	 * @param {[string]} opts.formSubheader override used by pathways API
	 */
	handleRequestMoreInfoClick = (vin, opts) => {
		const { clickEvent, ctaText } = opts;
		const vehicle = this.getVehicleByVin(vin);
		const { dealer: currentDealer } = this.props.autofiData;
		invoke(clickEvent, 'preventDefault');
		invoke(clickEvent, 'stopImmediatePropagation');
		invoke(clickEvent, 'stopPropagation');
		trackAdobePixelEvent('formStart', 'requestMoreInfoLead');
		trackJdPowerEvent('DRS-Start', vehicle, currentDealer);
		const pathway = Pathways.RequestMoreInfo;
		this.launchPathway(pathway, vin, clickEvent || ctaText, opts);
	};

	handleRequestMoreInfoSubmit = (applicant, callback = () => {}) => {
		const { currentVehicle } = this.state;

		trackAdobePixelEvent('formSubmit', 'requestMoreInfoLead');

		this.setState({ applicant });
		this.handleLead(Pathways.RequestMoreInfo, currentVehicle.vin, callback);
	};

	/**
	 * Click on Schedule test Drive Button
	 *
	 * @param {string} vin
	 * @param {object} opts
	 * @param {ClickEvent} opts.clickEvent
	 * @param {ClickEvent} opts.ctaText
	 * @param {[string]} opts.formHeader override used by pathways API
	 * @param {[string]} opts.formSubheader override used by pathways API
	 */
	handleScheduleTestDriveClick = (vin, opts) => {
		const { clickEvent, ctaText } = opts;
		const { dealer: currentDealer } = this.props.autofiData;
		const vehicle = this.getVehicleByVin(vin);

		invoke(clickEvent, 'preventDefault');
		invoke(clickEvent, 'stopImmediatePropagation');
		trackAdobePixelEvent('formStart', 'scheduleTestDriveLead');
		trackJdPowerEvent('DRS-Start', vehicle, currentDealer);
		trackJdPowerEvent('DRS-Test-Drive', vehicle, currentDealer);

		this.launchPathway(Pathways.TestDrive, vin, clickEvent || ctaText, opts);
	};

	handleScheduleTestDriveSubmit = (applicant, callback = () => {}) => {
		const { currentVehicle } = this.state;
		trackAdobePixelEvent('formSubmit', 'scheduleTestDriveLead');

		this.setState({ applicant });
		this.handleLead(Pathways.TestDrive, currentVehicle.vin, callback);
	};

	/**
	 * Click on Credit App Banner
	 *
	 * @param {[string]} vin usually undefined. This param is for consistency
	 *     with other click handlers.
	 * @param {object} opts
	 * @param {ClickEvent} opts.clickEvent
	 * @param {ClickEvent} opts.ctaText
	 * @param {[string]} opts.formHeader override used by pathways API
	 * @param {[string]} opts.formSubheader override used by pathways API
	 */
	handleCreditAppClick = (vin, opts) => {
		const { clickEvent, ctaText } = opts;

		trackAdobePixelEvent('formStart', 'creditEstimatorLead');
		invoke(clickEvent, 'preventDefault');
		invoke(clickEvent, 'stopPropagation');

		this.launchPathway(Pathways.CreditApp, vin, clickEvent || ctaText, opts);
	};

	handleCreditAppSubmit = (applicant, callback = () => {}) => {
		trackAdobePixelEvent('formSubmit', 'creditEstimatorLead');
		this.setState({ applicant }, callback);
	};

	getPrivateOfferEstimate = (completeVehicle, applicant) =>
		new Promise((resolve, reject) => {
			const { autofiData } = this.props;
			const { preferences } = this.state;
			const defaultOfferType = utils.getDefaultOfferType(autofiData);
			const { FINANCING, LEASING } = OfferType;
			const otherOfferType = defaultOfferType.toLowerCase() === LEASING ? FINANCING : LEASING;

			getEstimate(
				defaultOfferType,
				preferences,
				completeVehicle,
				autofiData,
				applicant,
				(defaultOfferTypeError, defaultOfferTypeResponse) => {
					if (defaultOfferTypeError) {
						getEstimate(
							otherOfferType,
							preferences,
							completeVehicle,
							autofiData,
							applicant,
							(otherOfferTypeError, otherOfferTypeResponse) => {
								if (otherOfferTypeError) {
									reject(otherOfferTypeError);
								} else {
									resolve(otherOfferTypeResponse);
								}
							}
						);
					} else {
						resolve(defaultOfferTypeResponse);
					}
				}
			);
		});

	/**
	 * @param {string} vin
	 * @param {object} opts
	 * @param {ClickEvent} opts.clickEvent
	 * @param {ClickEvent} opts.ctaText
	 * @param {[string]} opts.formHeader override used by pathways API
	 * @param {[string]} opts.formSubheader override used by pathways API
	 */
	handlePrivateOffersClick = async (vin, opts) => {
		const { dealer: currentDealer } = this.props.autofiData;
		const { clickEvent, ctaText } = opts;

		if (currentDealer.settings.pricing.rebatesConfig.includePrivateOffers) {
			invoke(clickEvent, 'preventDefault');
			invoke(clickEvent, 'stopPropagation');
			this.launchPathway(Pathways.PrivateOffers, vin, clickEvent || ctaText, opts);

			const completeVehicle = await this.getCompleteVehicleByVin(vin);

			const { applicant } = this.state;
			const requiredFieldsFilled = PrivateOffersForm.allRequiredFieldsFilled(currentDealer, applicant);
			const vehicleIsValid = utils.vehicleIsValidForEstimator(completeVehicle);

			if (requiredFieldsFilled && vehicleIsValid) {
				// skip form, direct to loading page and call estimator
				this.setState({ privateOffersStartPageIndex: 1 });
			}
		}
	};

	handlePrivateOffersSubmit = async (applicant, vehicle, callback = () => {}) => {
		const completeVehicle = vehicle ? await this.getCompleteVehicleByVin(vehicle.vin) : undefined;
		const vehicleIsValid = utils.vehicleIsValidForEstimator(completeVehicle);
		if (completeVehicle && vehicleIsValid) {
			this.setState({ iframeIsLoading: true, iframePathway: null, iframeSrc: '' });
			await this.handlePrivateOffersEstimate(applicant, completeVehicle);
			this.handleLead(Pathways.PrivateOffers, completeVehicle.vin, callback);
		} else {
			this.showThankYouMessage(Pathways.PrivateOffers, completeVehicle, callback);
		}
	};

	handlePrivateOffersEstimate = async (applicant, completeVehicle) => {
		const { autofiData, pageType } = this.props;
		const { loanAppId, trackId } = this.state;
		const vehicleIsValid = utils.vehicleIsValidForEstimator(completeVehicle);
		if (completeVehicle && vehicleIsValid) {
			let estimate;
			try {
				estimate = await this.getPrivateOfferEstimate(completeVehicle, applicant);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}

			const { dealerDiscount, OEMDiscount, privateOffers } = estimate || {};

			if (privateOffers) {
				applicant.privateOffers = privateOffers;
				const hasPrivateOffers = utils.hasPrivateOffers(estimate);
				const totalOfferAmount = sumBy(privateOffers, 'amount');

				const { dealer, sessionData } = autofiData;
				const { isInStore } = sessionData || {};

				trackAmplitudeEvent({
					action: AmplitudeLabelComponents.Action.Load,
					dealer,
					estimate,
					isInStore,
					label: AmplitudeLabelComponents.Label.PathwayPrivateOfferDisplay,
					opts: {
						isTestUser: utils.isTestUser(applicant),
						loanAppId,
						privateOfferAmount: totalOfferAmount,
						privateOfferDisplayed: hasPrivateOffers,
						privateOfferOEM: OEMs.FORD,
					},
					pageType,
					pathway: Pathways.PrivateOffers,
					trackId,
					vehicle: completeVehicle,
				});
			}
			this.setState({
				applicant,
				dealerDiscount,
				OEMDiscount,
				...(privateOffers && { privateOffers }),
			});
		}
	};

	/**
	 * Click on standalone credit app CTA
	 *
	 * @param {[string]} vin usually undefined. This param is for consistency
	 *     with other click handlers.
	 * @param {object} opts
	 * @param {ClickEvent} opts.clickEvent
	 * @param {ClickEvent} opts.ctaText
	 */
	handleStandAloneCreditAppClick = (vin, opts) => {
		const { clickEvent, ctaText } = opts;
		const { dealer: currentDealer } = this.props.autofiData;
		const vehicle = this.getVehicleByVin(vin);

		trackAdobePixelEvent('formStart', 'standaloneAppLead');
		trackJdPowerEvent('DRS-Credit-Application', vehicle, currentDealer);

		invoke(clickEvent, 'preventDefault');
		invoke(clickEvent, 'stopImmediatePropagation');

		const pathway = Pathways.StandAloneCreditApp;
		this.launchPathway(pathway, vin, clickEvent || ctaText, opts);
	};

	handleTradeInClick = (vin, opts) => {
		const { autofiData } = this.props;
		const { clickEvent, ctaText } = opts;
		const { dealer: currentDealer } = autofiData;
		const vehicle = this.getVehicleByVin(vin);

		trackAdobePixelEvent('formStart', 'tradeInLead');
		trackJdPowerEvent('DRS-Trade-in', vehicle, currentDealer);
		this.setState({ iframeIsLoading: true });

		invoke(clickEvent, 'preventDefault');
		invoke(clickEvent, 'stopImmediatePropagation');

		const pathway = Pathways.TradeIn;
		this.launchPathway(pathway, vin, clickEvent || ctaText, opts);
	};

	takeOverDealerVehiclelessCTAs = () => {
		const { dealerVehiclelessCtaMap } = this.props;

		if (dealerVehiclelessCtaMap) {
			[
				['creditApp', this.handleCreditAppClick],
				['standAloneCreditApp', this.handleStandAloneCreditAppClick],
				['deposit', this.handleDepositPaymentClick],
			].forEach(([ctaType, handler]) => {
				utils.takeOverDealerCTAs(
					dealerVehiclelessCtaMap[ctaType] || [],
					(clickEvent) => {
						const vin = undefined;
						handler(vin, { clickEvent });
					},
					ctaType
				);
			});
		}
	};

	shouldShowLoadingAnimation = () => {
		const { currentPathway, currentVehicle, currentView, iframeIsLoading, onVehicleDataQueues } = this.state;
		const vehicleIsPending = get(currentVehicle, 'status') === VehicleStatus.Pending;
		const waitingForVehicleData =
			vehicleIsPending && currentPathway && onVehicleDataQueues[currentVehicle.vin]?.length > 0;

		return Boolean(
			(!currentView || [Pathways.StandAloneCreditApp, Pathways.TradeIn].includes(currentPathway)) &&
				(waitingForVehicleData || iframeIsLoading) &&
				currentPathway !== Pathways.DriveTogether
		);
	};

	getCompleteVehicleByVin = (vin) =>
		new Promise((resolve) =>
			this.setState((prevState) => ({
				onVehicleDataQueues: {
					...prevState.onVehicleDataQueues,
					[vin]: [...(prevState.onVehicleDataQueues[vin] || []), (completeVehicle) => resolve(completeVehicle)],
				},
			}))
		);

	hideNodes = () => {
		const { nodesToRemove } = this.props.autofiData.dealer.websiteSettings.scraper.details;

		(nodesToRemove || []).forEach((selector) =>
			[...document.querySelectorAll(selector)].forEach(($node) =>
				$node.style.setProperty('display', 'none', 'important')
			)
		);
	};

	vehicleNeedsEstimate = (vehicle) => {
		const { autofiData, pageType } = this.props;
		const { dealer: currentDealer } = autofiData;
		const { showPaymentDetailsOnButton } = currentDealer.websiteSettings.ui[pageType];
		return (
			vehicle.status === VehicleStatus.OK &&
			((showPaymentDetailsOnButton && vehicle.ui.autofiCtaStuff.some((x) => x.$ctaContainer)) ||
				utils.lockedPricingIsEnabled(currentDealer, vehicle))
		);
	};

	queueMissingEstimates = () => {
		const { autofiData } = this.props;
		const { estimatesByVin, estimatorErrorsByVin, preferences, vehiclesByVin } = this.state;

		const vehiclesWithoutEstimatesByVin = omit(vehiclesByVin, [
			...this.estimateVinsInProgress,
			...keys(estimatesByVin),
			...keys(estimatorErrorsByVin),
		]);

		const defaultOfferType = utils.getDefaultOfferType(autofiData);
		const offerType = (preferences?.requestedOfferType || defaultOfferType)?.toLowerCase();

		Object.values(vehiclesWithoutEstimatesByVin)
			.filter(this.vehicleNeedsEstimate)
			.forEach((vehicle) => {
				this.estimateVinsInProgress.add(vehicle.vin);
				this.estimatorQueue.push({ isFirstAttempt: true, offerType, vehicle });
			});
	};

	callEstimator = ({ isFirstAttempt, offerType, vehicle }, callback) => {
		const { autofiData } = this.props;
		const { preferences } = this.state;

		const otherOfferType = offerType === OfferType.LEASING ? OfferType.FINANCING : OfferType.LEASING;

		getEstimate(
			offerType === OfferType.CASH ? OfferType.FINANCING : offerType,
			preferences,
			vehicle,
			autofiData,
			{},
			(error, estimate) => {
				if (error && isFirstAttempt) {
					// retry with the other offer type
					this.callEstimator({ isFirstAttempt: false, offerType: otherOfferType, vehicle }, callback);
				} else if (error || !estimate) {
					this.estimateVinsInProgress.delete(vehicle.vin);
					this.handleEstimatorError(vehicle, error);
					callback(error || 'Estimate Not Found');
				} else {
					this.estimateVinsInProgress.delete(vehicle.vin);
					this.handleEstimate(vehicle, estimate);
					callback();
				}
			}
		);
	};

	handleEstimate = (vehicle, estimate) => {
		this.setState((prevState) => ({
			estimatesByVin: { ...prevState.estimatesByVin, [vehicle.vin]: estimate },
		}));
	};

	handleEstimatorError = (vehicle, err) => {
		this.setState((prevState) => ({
			estimatorErrorsByVin: { ...prevState.estimatorErrorsByVin, [vehicle.vin]: err },
		}));
	};

	// TODO: find a nice way to put this in api-based.jsx
	setPathwaysApiCallback = (callback) => {
		if (callback) {
			this.setState({ pathwaysApiCallback: callback });
		}
	};

	render() {
		const { autofiData, pageType } = this.props;

		const {
			applicant,
			autofiVehiclelessCtaMap,
			currentPathway,
			currentVehicle,
			currentView,
			dealerDiscount,
			estimatesByVin,
			iframeSrc,
			loanAppId,
			OEMDiscount,
			pathwaysApiCallback,
			pathwayTitles,
			privateOffers,
			privateOffersStartPageIndex,
			trackId,
			userIsInSession,
			vehiclesByVin,
		} = this.state;

		const { dealer: currentDealer, driveDealer, launchDarklyFeatureFlags, sessionData } = autofiData;
		const { isInStore } = sessionData || {};

		const pageLanguage = utils.getLanguageTag(get(currentVehicle, 'dealer', currentDealer));
		const { preferredLanguage } = currentDealer.settings;
		// TODO: Figure out how to handle switching dictionary based on customer preference
		const dictionary = loadDictionary(pageLanguage || preferredLanguage);

		const shouldShowLoadingAnimation = this.shouldShowLoadingAnimation();

		const estimates = Object.keys(estimatesByVin);
		const currentEstimate = estimates.length === 1 ? estimatesByVin[estimates[0]] : estimatesByVin[currentVehicle?.vin];
		const currentDiscountAndRebatesAmount = utils.getDiscountsAndRebatesAmount(currentVehicle, currentEstimate);

		const { currentSubTitle, currentTitle } = pathwayTitles;

		return (
			<utils.CustomCssContext.Provider value={currentDealer.websiteSettings.ui.customCss}>
				<Fragment>
					{
						// Removing the pageType === 'api' check will allow our pathways API and
						// scraping logic to try to run at the same time, but we will need to make
						// further changes for everything to work correctly. See
						// https://autofi.atlassian.net/browse/DIGRTL-179811842
						pageType === 'api' && (
							<PathwaysApiUi
								autofiData={autofiData}
								callback={pathwaysApiCallback}
								ctaTypeClickHandlerMap={this.ctaTypeClickHandlerMap}
								handleLoadLoanApplication={this.handleLoadLoanApplication}
								setApplicant={this.setApplicant}
								setCallback={this.setPathwaysApiCallback}
								setCurrentVehicle={this.setCurrentVehicle}
							/>
						)
					}

					<SharedDumbUI
						applicant={applicant}
						autofiData={autofiData}
						autofiVehiclelessCtaMap={autofiVehiclelessCtaMap}
						closeModal={this.closeModal}
						currentPathway={currentPathway}
						currentSubTitle={currentSubTitle}
						currentTitle={currentTitle}
						currentVehicle={currentVehicle}
						currentView={currentView}
						dealerDiscount={dealerDiscount}
						dictionary={dictionary}
						discountAndRebatesAmount={currentDiscountAndRebatesAmount}
						handleCreditAppClick={this.handleCreditAppClick}
						handleCreditAppSubmit={this.handleCreditAppSubmit}
						handleDepositPaymentSubmit={this.handleDepositPaymentSubmit}
						handleExplorePaymentsSubmit={this.handleExplorePaymentsSubmit}
						handlePrivateOffersSubmit={this.handlePrivateOffersSubmit}
						handleRequestMoreInfoSubmit={this.handleRequestMoreInfoSubmit}
						handleScheduleTestDriveSubmit={this.handleScheduleTestDriveSubmit}
						handleStandAloneCreditAppClick={this.handleStandAloneCreditAppClick}
						iframeSrc={iframeSrc}
						loanAppId={loanAppId}
						OEMDiscount={OEMDiscount}
						onModalIframeLoad={this.onModalIframeLoad}
						pageType={pageType}
						privateOffers={privateOffers}
						privateOffersStartPageIndex={privateOffersStartPageIndex}
						shouldShowLoadingAnimation={shouldShowLoadingAnimation}
						trackAmplitudeEvent={this.trackAmplitudeEventWithCurrentData}
						userIsInSession={userIsInSession}
					/>

					{Object.values(vehiclesByVin).map((vehicle) => (
						<VehicleAutofiCTAs
							applicant={applicant}
							ctaTypeClickHandlerMap={this.ctaTypeClickHandlerMap}
							currentDealer={currentDealer}
							dictionary={dictionary}
							driveDealer={driveDealer}
							estimate={estimatesByVin[vehicle.vin]}
							featureFlags={launchDarklyFeatureFlags}
							isInStore={isInStore}
							key={vehicle.vin}
							pageType={pageType}
							trackId={trackId}
							vehicle={vehicle}
						/>
					))}

					<DriveTogetherOrchestrator
						vehiclesByVin={vehiclesByVin}
						driveDealer={driveDealer}
						featureFlags={launchDarklyFeatureFlags}
					/>
				</Fragment>
			</utils.CustomCssContext.Provider>
		);
	}
}

export class MainUI {
	render = async (scraperData, autofiData) => {
		const { pageType } = scraperData;
		const { dealer: currentDealer } = autofiData;
		const normalizedQuery = parseQuery(window.location.href);
		const afEnable = normalizedQuery['af-enable'] === 'true';
		if (!currentDealer.isLive && !afEnable) {
			return;
		}
		const { autofiVehiclelessCtaMap, dealerVehiclelessCtaMap, shiftDigitalSessionId, vehicles } = scraperData;

		embedGoogleAnalytics4(currentDealer);

		if (!this.$container) {
			this.$container = document.createElement('div');
			document.body.appendChild(this.$container);
		}

		render(
			<MainUiComponent
				autofiData={autofiData}
				dealerVehiclelessCtaMap={dealerVehiclelessCtaMap}
				pageType={pageType}
				ref={(ui) => (this.uiInstance = ui)}
			/>,
			this.$container
		);

		if (vehicles) {
			await this.uiInstance.addVehicles(vehicles);

			if (pageType === 'details') {
				const vehicle = vehicles[0];
				if (vehicle) {
					const vehicleConstraints = get(vehicle.dealer, 'websiteSettings.ui.vehicleConstraints');
					const matchingConstraint = utils.matchingConstraint(vehicle, vehicleConstraints);
					if (matchingConstraint) {
						// TODO: don't mutate dealer
						const dealerToMutate = currentDealer.alwaysShowLeadgen || !vehicle.dealer ? currentDealer : vehicle.dealer;
						utils.applyConstraintOverrides(matchingConstraint.overrides, dealerToMutate.websiteSettings.ui);
					}
				}
			}
		}

		if (autofiVehiclelessCtaMap) {
			this.uiInstance.setAutofiVehiclelessCtaMap(autofiVehiclelessCtaMap);
		}

		if (shiftDigitalSessionId) {
			this.uiInstance.setState({ shiftDigitalSessionId });
		}
	};
}
