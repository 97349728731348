import defaultTo from 'lodash/defaultTo';
import drop from 'lodash/drop';
import dropRight from 'lodash/dropRight';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import pick from 'lodash/pick';
import set from 'lodash/set';
import sumBy from 'lodash/sumBy';
import dateFormat from 'date-fns/format';
import { createContext } from 'preact';
import { loadStripe } from '@stripe/stripe-js';

import parseQuery from '~lib/parse-query';
import localize from '~lib/dictionaries/localize';
import session from './consumerSession';
import HTTPClient from './HTTPClient';
import * as analyticsUtils from './analytics';
import { DealMakerPanel, OfferType, Pathways, ProductionTestUserEmails, TestAppNames, VehicleStatus } from '~lib/enum';

// require.context causes everything in the directory passed to it to be
// included in the bundle and accessible to anything that requires this
// file.
const getCustomStyleFile = require.context('~ui/customStyles');

export const getCustomStyles = (customCss) => {
	try {
		// TODO: remove the following line and use customCss directly after
		// extensions are removed from all dealer customCss settings.
		const customCssName = customCss.replace(/\.css$/i, '');
		return getCustomStyleFile(`./${customCssName}.styl`);
	} catch (ex) {
		return null;
	}
};

export const trimmedLowerCase = (str = '') => str.toString().toLowerCase().trim();

export const constraintMatches = (vehicle, constraint) => {
	if (!vehicle.dealerRetailPrice || vehicle.dealerRetailPrice <= 0) {
		warnToConsole(`Constraint mismatches - invalid dealerRetailPrice: ${vehicle.dealerRetailPrice}`);
		return false;
	}

	if (
		constraint.age &&
		trimmedLowerCase(vehicle.age) !== trimmedLowerCase(constraint.age) &&
		trimmedLowerCase(constraint.age) !== 'any'
	) {
		warnToConsole(`Constraint mismatches - age: ${constraint.age}`);
		return false;
	}

	// NOTE: do not render XC on used vehicles over six years old
	// we don't have rate sheets for > 6 year old cars
	if (new Date().getFullYear() - vehicle.year > Math.floor((constraint.maxMonthsOld || 72) / 12)) {
		warnToConsole(`Constraint mismatches - max month old: ${constraint.maxMonthsOld}`);
		return false;
	}

	if (constraint.make && trimmedLowerCase(constraint.make) !== trimmedLowerCase(vehicle.make)) {
		warnToConsole(`Constraint mismatches - make: ${constraint.make}`);
		return false;
	}

	const bodyType = vehicle.bodyType || vehicle.bodyStyle || '';
	const disallowedBodyTypes = constraint.disallowedBodyTypes
		? constraint.disallowedBodyTypes.split(',').filter(Boolean)
		: [];
	if (disallowedBodyTypes.map(trimmedLowerCase).includes(trimmedLowerCase(bodyType))) {
		warnToConsole(`Constraint mismatches - disallowed body type: ${disallowedBodyTypes}`);
		return false;
	}

	if (constraint.minPrice && vehicle.salePrice < constraint.minPrice) {
		warnToConsole(`Constraint mismatches - min price: ${constraint.minPrice}`);
		return false;
	}

	if (constraint.maxPrice && vehicle.salePrice > constraint.maxPrice) {
		warnToConsole(`Constraint mismatches - max price: ${constraint.maxPrice}`);
		return false;
	}

	const vehicleDescription = [vehicle.make, vehicle.model, vehicle.trim, vehicle.year]
		.filter(Boolean)
		.map(trimmedLowerCase)
		.join(' ');

	if (
		constraint.disallowedMakeModel &&
		new RegExp(trimmedLowerCase(constraint.disallowedMakeModel)).test(vehicleDescription)
	) {
		warnToConsole(`Constraint mismatches - disallowed make/model: ${constraint.disallowedMakeModel}`);
		return false;
	}

	const anyDisallowedVins = (constraint.disallowedVins || []).some((vin) =>
		new RegExp(trimmedLowerCase(vin)).test(trimmedLowerCase(vehicle.vin))
	);
	if (anyDisallowedVins) {
		warnToConsole('Constraint mismatches - disallowed vin');
	}
	return !anyDisallowedVins;
};

export const matchingConstraint = (vehicle, constraints) => {
	const constraintsForAge = (constraints || []).filter((c) => c.age === vehicle.age);
	const allConstraintsForAgeMatch =
		constraintsForAge.length > 0 && constraintsForAge.every((c) => constraintMatches(vehicle, c));

	if (allConstraintsForAgeMatch) {
		return constraintsForAge[0];
	} else {
		return undefined;
	}
};

// TODO: call this something clearer. Maybe getLocale.
export const getLanguageTag = (dealer) => {
	const langAttr = document.documentElement.lang || '';
	const [siteLanguage, siteCountry] = langAttr.split('-');
	if (!siteLanguage) {
		return undefined;
	}
	const dealerCountry = dealer.address.contracts.country;
	let country;
	// Select the correct locale for Canadian dealers that have a lang
	// attribute of en-US. TODO: find a less hacky way to deal with this.
	if (dealerCountry === 'CA' && siteCountry === 'US') {
		country = dealerCountry;
	} else {
		country = siteCountry || dealerCountry;
	}
	return `${siteLanguage}-${country}`;
};

// TODO: make this function take dealer instead of autofiData.
export const getDefaultOfferType = (autofiData) => {
	const { dealer } = autofiData;
	const defaultOfferType = get(dealer, 'websiteSettings.ui.defaults.offerType', 'financing');
	return OfferType[defaultOfferType.toUpperCase()];
};

export const lockedPricingIsEnabled = (currentDealer, vehicle) => {
	if (isEmpty(vehicle) || vehicle.status === VehicleStatus.Pending) {
		return false;
	}

	// vehicle.dealer may not be defined if there is no data for vehicle.
	const { features } = get(vehicle, 'dealer', currentDealer).websiteSettings.ui;
	const settings = get(features, 'lockedPricing', {});
	if (!settings.enabled) {
		return false;
	}

	const constraints = settings.constraints.filter((c) => Boolean(c));

	const constraintMatched = constraints.some((c) => constraintMatches(vehicle, c));

	const hasDiscounts = sumBy(vehicle.discounts, 'amount') > 0;
	// no non-empty constraints means all vehicles are locked
	return (constraintMatched || constraints.length === 0) && hasDiscounts;
};

export const pricingIsLocked = (vehicle, dealer, applicant) => {
	return lockedPricingIsEnabled(dealer, vehicle) && !applicantHasContactInfoInSession(applicant, dealer);
};

export const isValidPanelOption = (option) => {
	const panelOptions = Object.values(DealMakerPanel);
	return panelOptions.includes(option);
};

export const updateApplicant = (userInfo, applicant) => {
	const first = get(userInfo, 'first', '');
	const middle = get(userInfo, 'middle', '');
	const last = get(userInfo, 'last', '');
	const full = `${first} ${last}`.trim();
	return {
		...applicant,
		...pick(userInfo, ['address', 'email', 'income', 'phone']),
		name: {
			first,
			middle,
			last,
			full,
		},
	};
};

export const makeApplicant = (applicantOptions) => {
	const { comments, contactData, dealer, income } = applicantOptions;
	const { address, websiteSettings } = dealer;
	const dealerAddress = address.contracts;
	const { incentiveOption, ui } = websiteSettings;
	const { useDefaultTaxes } = ui.features;
	const phone = get(contactData, 'phone');
	const userAddress = pick(contactData, ['city', 'state', 'street', 'street2', 'zip']);

	if (useDefaultTaxes) {
		userAddress.zip = userAddress.zip || dealerAddress.zip;
		userAddress.state = userAddress.state || dealerAddress.state;
	}

	return {
		address: userAddress,
		appointment: { comments },
		email: get(contactData, 'email', null),
		income,
		name: parseName(get(contactData, 'name', ''), contactData?.middleInitial),
		phone: isNil(phone) ? null : phone.replace(/\D/g, ''),
		preferences: {
			incentiveOption,
			contactMethod: get(contactData, 'contactMethod'),
		},
		rememberMe: get(contactData, 'rememberMe', true),
	};
};

export const makeApplicantWithTestDriveAppointment = (applicantOptions) => {
	return {
		...makeApplicant(applicantOptions),
		appointment: {
			comments: 'Test drive',
			createdAs: 'scheduleTestDrive',
			scheduledTime: dateFormat(applicantOptions.scheduledTime, 'YYYY-MM-DD HH:mm'),
		},
	};
};

export const applicantHasContactInfoInSession = (applicant, dealer) => {
	const { email, name, phone, rememberMe } = applicant || {};

	if (!rememberMe) {
		return false;
	} else {
		const requirePhone = get(dealer, 'websiteSettings.ui.features.getYourPrice.requirePhone', false);
		return Boolean(name.full && email && (requirePhone ? phone : true));
	}
};

export const getSoftPullUrl = (apiProxyUrl, currentUrl) => {
	const currentUrlObj = new URL(currentUrl);
	const endpointPathParams = currentUrlObj.searchParams.getAll('af-endpoint');
	const loanAppPrParams = currentUrlObj.searchParams.getAll('af-loanapp-pr');

	if (loanAppPrParams.length > 1) {
		throw new Error("can't get soft pull URL with multiple af-loanapp-pr query params");
	} else if (loanAppPrParams[0]) {
		return `https://loanapp-staging-pr-${loanAppPrParams[0]}.pr.core.autofi.io/api/v1/creditreport/softpull`;
	}

	if (endpointPathParams.length > 1) {
		throw new Error("can't get soft pull URL with multiple af-endpoint query params");
	} else if (endpointPathParams[0]) {
		return `${endpointPathParams[0]}/api/v1/creditreport/softpull`;
	}

	return `${apiProxyUrl}/v1/creditreport/softpull`;
};

export const makeSoftPullPayload = (applicant, autofiData, loanAppId) => {
	const { backendPath, sessionData, smartCowPR, dealer } = autofiData;
	return {
		...pick(applicant, ['address', 'email', 'income', 'name', 'phone', 'rememberMe']),
		backendPath,
		consumerSession: session.getSessionId(sessionData),
		isInStore: sessionData.isInStore,
		...(loanAppId && { loanAppId }),
		smartCowPR,
		fullReferrerURL: window.location.href,
		dealerCode: dealer.code,
	};
};

export const parseName = (rawFullName, middleInitial) => {
	const fullName = rawFullName.trim();
	const middle = middleInitial ? middleInitial.trim() : '';
	const names = fullName.split(' ');
	let nameObj = {};
	if (names.length > 1) {
		nameObj = { first: dropRight(names).join(' '), middle, last: last(names), full: fullName };
	} else {
		nameObj = { first: names.join(' '), middle, last: '', full: fullName };
	}
	return nameObj;
};

export const getExpressCheckoutPayloadData = (pageType, vehicle, offerType, autofiData) => {
	const dealer = vehicle.dealer || autofiData.dealer;
	const { defaults, features } = dealer.websiteSettings.ui;
	const creditBand = get(defaults, [offerType.toLowerCase(), 'credit']);

	const expressCheckout = {
		defaultTab: defaults.offerType,
		pageType,
		preferences: {
			creditBand,
			finance: {
				disabled: !features.showFinance,
				termMonths: defaults.finance.term,
				downPayment: defaults.finance.downPayment,
				downPaymentPercentage: defaults.finance.downPaymentPercentage,
			},
			lease: {
				disabled: !features.showLease,
				termMonths: defaults.lease.term,
				downPayment: defaults.lease.downPayment,
				downPaymentPercentage: defaults.lease.downPaymentPercentage,
				annualMileage: defaults.lease.annualMiles,
			},
		},
	};

	// only add locked pricing attribute if it's enabled
	if (lockedPricingIsEnabled(autofiData.dealer, vehicle)) {
		expressCheckout.lockedPricing = { active: true };
	}

	return expressCheckout;
};

export const getStartEndpoint = (autofiData) => {
	const normalizedQuery = parseQuery(window.location.href);

	const endpoint = normalizedQuery['af-start-endpoint'];
	if (endpoint) {
		return endpoint;
	}

	return `${autofiData.loanAppBaseUrl}/start`;
};

/**
 * Create a loanapp
 * @param {object} applicant object returned from modal submit or created with utils.makeApplicant
 * @param {object} vehicle
 * @param {string} pageType: details, listings, or other
 * @param {object} miscOptions optional attributes
 * @param {object} autofiData
 * @param {function} callback called with ({string} err, {string} iframeSrc)
 */
export const createLoanApp = (applicant, vehicle, pageType, miscOptions, autofiData, callback) => {
	const payload = makeLoanAppPayload(applicant, pageType, miscOptions, vehicle, autofiData);

	HTTPClient.post(
		{
			endpoint: {
				url: getStartEndpoint(autofiData),
				secret: vehicle.dealer.settings.buyNow.secret,
			},
			// TODO: remove this withCredentials setting. It is unnecessary, has no
			// effect on the request, and can make it fail if the server doesn't return
			// the right Access-Control-Allow-Origin header.
			withCredentials: true,
			payload,
		},
		(err, rawResponse) => {
			try {
				if (err) {
					const error = JSON.parse(err);
					callback(error.message);
					return;
				}
				const response = JSON.parse(rawResponse);
				callback(null, response);
			} catch (ex) {
				callback('Sorry! Something went wrong on our end. Please try again later.');
			}
		}
	);
};

export const getDealMakerIframeUrl = (loanAppResponseUrl, pathway, { isInStore }) => {
	if (pathway === Pathways.Deposit) {
		return '';
	}

	const iframeSrcObj = new URL(loanAppResponseUrl);
	let query;
	if (pathway === Pathways.TradeIn) {
		iframeSrcObj.pathname += '/trade-in';
		query = { 'af-instore': isInStore, postMessageTarget: window.location.href };
	} else {
		query = { postMessageTarget: window.location.href };
	}
	iframeSrcObj.search = new URLSearchParams(query);

	return iframeSrcObj.toString();
};

export const getInvalidVehicleLeadUrl = (apiProxyUrl, currentUrl) => {
	const currentUrlObj = new URL(currentUrl);
	const endpointPathParams = currentUrlObj.searchParams.getAll('af-endpoint');
	const loanAppPrParams = currentUrlObj.searchParams.getAll('af-loanapp-pr');

	if (loanAppPrParams.length > 1) {
		throw new Error("can't get invalid vehicle lead URL with multiple af-loanapp-pr query params");
	} else if (loanAppPrParams[0]) {
		return `https://loanapp-staging-pr-${loanAppPrParams[0]}.pr.core.autofi.io/api/v1/invalid-vehicle-lead`;
	}

	if (endpointPathParams.length > 1) {
		throw new Error("can't get invalid vehicle lead URL with multiple af-endpoint query params");
	} else if (endpointPathParams[0]) {
		return `${endpointPathParams[0]}/api/v1/invalid-vehicle-lead`;
	}

	return `${apiProxyUrl}/v1/invalid-vehicle-lead`;
};

/**
 * Create a lead for a customer with an invalid vehicle
 * @param {object} applicant object returned from modal submit or created with utils.makeApplicant
 * @param {object} vehicle
 * @param {string} pageType details, listings, or other
 * @param {object} miscOptions optional attributes, i.e. trackId, preferences, pathway
 * @param {object} autofiData
 * @param {function} callback called with ({string} err)
 */
export const createInvalidVehicleLead = async (
	applicant,
	vehicle,
	pageType,
	miscOptions,
	autofiData,
	callback = () => {}
) => {
	const { apiProxyUrl, dealer: currentDealer } = autofiData;
	const payload = makeLoanAppPayload(applicant, pageType, miscOptions, vehicle, autofiData);
	const url = getInvalidVehicleLeadUrl(apiProxyUrl, window.location.href);
	const { secret } = (vehicle.dealer || currentDealer).settings.buyNow;

	HTTPClient.post({ endpoint: { url, secret }, payload }, (err) => {
		const errorMessage = 'Sorry! Something went wrong on our end. Please try again later.';
		try {
			if (err) {
				return callback(errorMessage);
			}
			return callback(null);
		} catch (ex) {
			return callback(errorMessage);
		}
	});
};

export const getStandAloneCreditAppUrl = (apiProxyUrl, currentUrl) => {
	const normalizedQuery = parseQuery(currentUrl);
	const url = new URL(`${apiProxyUrl}/v1/credit-application`);

	const cobraPR = normalizedQuery['af-cobra-pr'];
	if (cobraPR) {
		url.searchParams.set('af-cobra-pr', cobraPR);
	}

	return url.toString();
};

/**
 * Create a stand alone credit application
 * @param {object} autofiData
 * @param {string} autofiData.apiProxyUrl
 * @param {string} autofiData.backendPath
 * @param {object} autofiData.dealer
 * @param {string} autofiData.loanAppBaseUrl
 * @param {function} callback called with ({string} err, {string} iframeSrc)
 */
export const createStandAloneCreditApplication = (autofiData, callback) => {
	const { apiProxyUrl, backendPath, dealer: currentDealer, loanAppBaseUrl } = autofiData;

	const url = getStandAloneCreditAppUrl(apiProxyUrl, window.location.href);
	const payload = { backendPath, dealerCode: currentDealer.code };

	HTTPClient.post({ endpoint: { url }, payload }, (err, res) => {
		const errorMessage = 'Sorry! Something went wrong on our end. Please try again later.';
		try {
			if (err) {
				return callback(errorMessage);
			}
			const responseData = JSON.parse(res);
			const iframeSrcObj = new URL(`${loanAppBaseUrl}/t2/${responseData.urlToken}`);
			iframeSrcObj.search = new URLSearchParams({ postMessageTarget: window.location.href });
			return callback(null, { iframeSrc: iframeSrcObj.toString() });
		} catch (ex) {
			return callback(errorMessage);
		}
	});
};

/**
 * Fetch and return existing loan application to load on iframeSrc
 *
 * @param {String} loanApplicationId
 * @param {string} dealMakerPanel
 * @param {Object} autofiData
 *
 * @returns {Promise<Object | Error>}
 */
export const fetchLoanApplication = (loanApplicationId, dealMakerPanel, autofiData) =>
	new Promise((resolve, reject) => {
		HTTPClient.get(
			{
				endpoint: {
					url: `${autofiData.loanAppBaseUrl}/api/v1/financing/${loanApplicationId}/urlToken`,
					secret: autofiData.dealer.settings.buyNow.secret,
				},
				withCredentials: true,
			},
			(err, res) => {
				try {
					if (err) {
						const error = JSON.parse(err);
						return reject(error);
					}
					const response = JSON.parse(res);
					const iframeSrc = new URL(`${autofiData.loanAppBaseUrl}/t2/${response.urlToken}`);
					iframeSrc.searchParams.set('postMessageTarget', window.location.href);
					if (dealMakerPanel === DealMakerPanel.StartCreditApp) {
						/** @todo Use a variable instead of a hardcoded value once we introduce more steps */
						iframeSrc.searchParams.set('currentStep', 'processeducation');
					}
					resolve({ url: iframeSrc.toString() });
				} catch (error) {
					reject(error);
				}
			}
		);
	});

export const prepareVehicleForLoanapp = (vehicle) => {
	// Avoid sending unnecessary things, including DOM nodes that
	// JSON.stringify can't handle and status value that confuses loanapp
	// validation code.
	return pick(vehicle, [
		'age',
		'algModel',
		'algStyle',
		'baseMsrp',
		'bodyType',
		'bookValue',
		'color',
		'dealer',
		'dealerRetailPrice',
		'discount',
		'discounts',
		'driveTrain',
		'fuelType',
		'invoice',
		'lastUpdated',
		'make',
		'maxDealerCash',
		'mileage',
		'model',
		'modelCode',
		'msrp',
		'packageCode', // This will only be available on V2 Vehicle Search vehicles
		'photoUrl',
		'preInstalledAccessories',
		'price', // deprecated
		'rawDiscount',
		'salePrice',
		'stockNumber',
		'trim',
		'trimCode',
		'vdpUrl',
		'vin',
		'year',
	]);
};

export const makeFordDirectIntegrations = () => {
	const firstNonEmptyValue = [...document.querySelectorAll('[data-tertiary-source]')]
		.map((e) => e.getAttribute('data-tertiary-source'))
		.find((value) => value.trim() !== '');

	const fordDirectIntegrations = firstNonEmptyValue
		? {
				fordDirect: {
					guid: firstNonEmptyValue,
				},
		  }
		: undefined;

	return fordDirectIntegrations;
};

const shouldCaptureFordDirectGUID = (dealer) => {
	const allowedBrands = ['ford', 'lincoln'];
	const allowedWebsiteProviders = ['do', 'di', 'ddc', 'sincro', 'jazel'];

	const isWebsiteProviderValid = allowedWebsiteProviders.includes(dealer?.settings?.websiteProvider ?? null);
	const isBrandValid = allowedBrands.includes(dealer?.brand?.toLowerCase() ?? null);
	const isFordDirectDealer = !!dealer?.settings?.analytics?.fordDirect?.enabled;

	return isWebsiteProviderValid && isBrandValid && isFordDirectDealer;
};

export const makeLoanAppPayload = (applicant, pageType, misc, vehicle, autofiData) => {
	const { backendPath, dealer: currentDealer, launchDarklyFeatureFlags, sessionData, smartCowPR } = autofiData;
	const { enableFordDirectGUID } = launchDarklyFeatureFlags;
	const dealer = vehicle.dealer || currentDealer;
	const buyNowVia = getBuyNowVia(dealer.websiteSettings.ui);
	const { discounts, preOfferRebates } = vehicle;
	const {
		apiKey,
		customerReferenceId,
		dealerCodeOverride,
		oemLeadSource,
		pathway,
		pathwayTitles = {},
		preferences,
		shiftDigitalSessionId,
	} = misc;
	const requestedOfferType = getRequestedOfferType(buyNowVia);
	const pricePlan = defaultTo(get(preferences, 'pricePlan'), currentDealer.settings.consumerFlow.defaultPricePlan);
	const trackId = defaultTo(misc.trackId, window.autofi.trackId);

	// this used to use an assumed single ID googleAnalyticsTrackingId configured per dealer
	// but now we have an array of IDs.
	// In Loanapp analytics.gaClientId is used ONLY for legacy GA (Universal) not for GA4
	// filter for an ID that starts with UA (UA-XXXXX-XX) and use that to fetch the client ID
	const gaClientId = analyticsUtils.getGaClientId(
		(currentDealer.settings.analytics?.googleAnalyticsMeasurementIds || []).find((id) =>
			id.toLowerCase().startsWith('ua')
		),
		sessionData
	);
	const consumerSession = session.getSessionId(sessionData);
	const { currentSubTitle, currentTitle } = pathwayTitles || {};

	return {
		analytics: {
			gaClientId,
		},
		discounts,
		preOfferRebates,
		buyNowVia,
		trackId,
		smartCowPR,
		applicant,

		vehicle: prepareVehicleForLoanapp(vehicle),

		...(currentDealer.settings.isGroupSite ? { groupSiteDealerCode: currentDealer.code } : {}),
		...(apiKey && { apiKey }),
		...(customerReferenceId ? { customerReferenceId } : { consumerSession }),

		dealerCode: dealerCodeOverride || dealer.code,
		dealerName: dealer.name,
		sendEmail: false,
		sendMobile: false,
		requestedOfferType,
		token: dealer.settings.buyNow.secret,
		expressCheckout: getExpressCheckoutPayloadData(pageType, vehicle, requestedOfferType, autofiData),
		loanInfo: {
			pricePlan: {
				plan: pricePlan,
			},
		},
		backendPath,
		pathway,
		...(Object.keys(pathwayTitles).length > 0 && {
			pathwayOverrides: [
				{
					pathway,
					currentSubTitle,
					currentTitle,
				},
			],
		}),
		fullReferrerURL: window.location.href,
		userStartedApplication: true,
		preferredLanguage: getLanguageTag(dealer),
		isInStore: sessionData.isInStore,
		oemLeadSource,
		integrations: {
			...(shouldCaptureFordDirectGUID(currentDealer) && enableFordDirectGUID && makeFordDirectIntegrations()),
			...(shiftDigitalSessionId && { shiftDigital: { leadSessionId: shiftDigitalSessionId } }),
		},
	};
};

export const nearest100 = (n) => {
	return Math.floor(n / 100) * 100 || 0;
};

/**
 * @param {overrides} pathway map of constraint overrides
 * @param {object} settings websiteSettings.ui object of dealer
 * @returns {undefined}
 */
export const applyConstraintOverrides = (overrides, settings) => {
	// TODO: don't mutate settings
	Object.entries(overrides || {}).forEach(([path, value]) => {
		const pathChunks = path.split('::');
		const moduleType = pathChunks[0];
		if (moduleType === 'ui') {
			const pList = drop(pathChunks, 1);
			set(settings, pList, value);
		}
	});
};

export const getBuyNowVia = (ui) => {
	const useExtendedOptions = !ui.features.usePlainBox;
	return useExtendedOptions ? ui.defaults.offerType : null;
};

export const getRequestedOfferType = (buyNowVia) => {
	const offerTypes = {
		cash: 'CASH',
		financing: 'FINANCE',
		leasing: 'LEASE',
	};
	return get(offerTypes, buyNowVia, 'FINANCE');
};

export const formatAddress = (address) => {
	return `${address.city}, ${address.state} ${address.zip}`;
};

export const formatMoney = (num, digits = 0) => {
	const formatted = formatNumber(Math.abs(num), digits);
	const prefix = num < 0 ? '-$' : '$';
	return prefix + formatted;
};

export const formatNumber = (num, digits = 0) => {
	let formatted = num + '';
	try {
		formatted = parseFloat(formatted.replace(/[$,\s-]/g, ''))
			.toFixed(digits)
			.replace(/./g, (c, i, a) => (i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c));
	} catch (ex) {
		// continue regardless of error
	}
	const prefix = num < 0 ? '-' : '';
	return prefix + formatted;
};

export const CustomCssContext = (() => {
	try {
		return createContext();
	} catch (ex) {
		return undefined;
	}
})();

export const getComponentName = (Component) => {
	return get(Component, 'displayName', Component.name);
};

export const getOverlays = () => {
	// '.hide-for-large' matches Hickory Toyota's nav bar.
	// '.nav-right-expand-search .navbar' matches Capital Ford of Wilmington's
	// nav bar. Both nav bars appear on top of our forms and can't easily be
	// hidden using z-index without messing up flatpickr.
	return [...document.querySelectorAll('.hide-for-large, .nav-right-expand-search .navbar')];
};

export const hideOverlays = () => {
	getOverlays().forEach(($el) => {
		$el.classList.add('autofi-hide-sm');

		// handle elements with z-index values greater than 1
		const { zIndex } = getComputedStyle($el);
		if (zIndex) {
			$el.setAttribute('data-original-z-index', zIndex);
			$el.style.zIndex = 1;
		}
	});
	hideTextSalesBtn();
};

export const showOverlays = () => {
	getOverlays().forEach(($el) => {
		$el.classList.remove('autofi-hide-sm');

		// handle elements with z-index values greater than 1
		const originalZIndex = $el.getAttribute('data-original-z-index') || null;
		if (originalZIndex) {
			$el.style.zIndex = originalZIndex;
		}
	});
	showTextSalesBtn();
};

export const hideTextSalesBtn = () => {
	// hide the "text sales" button that pops up over iframe / modals
	const $textBtn = document.getElementById('carcodesms-default-widget');
	if ($textBtn) {
		$textBtn.style.display = 'none';
	}
};

export const showTextSalesBtn = () => {
	// show the "text sales" button that pops up over iframe / modals
	const $textBtn = document.getElementById('carcodesms-default-widget');
	if ($textBtn) {
		$textBtn.style.display = 'block';
	}
};

export const isIos = () => {
	return /iphone|ipad|ipod/i.test(navigator.userAgent);
};

export const makeAddressHintMessage = (street) => {
	// display the right hint message
	if (street && street.trim()) {
		if (!isAddressWithNumber(street)) {
			return 'invalid-address-format';
		}
	}
	return '';
};

export const isPoBoxAddress = (street) => /^(p\.? ?o\.? box|p\.? ?b\.?) *\d+/i.test(street);
export const isAddressWithNumber = (street) => {
	const streetNumber = street.split(/\s+/)[0];

	if (/st|nd|rd|th/i.test(streetNumber)) {
		return false;
	}

	return /\d/.test(streetNumber) && /^\w+(\s+\w+|[-\w]+)/.test(street);
};

// TODO: find a way to handle a valid regex for CA and US dealers
export const streetRegex = /^(p\.? ?o\.? box|p\.? ?b\.?) *\d+|^\d+[\w+]?\s[#.,\w\s]{2,}/i;

export const validationPatterns = {
	// email pattern copied from https://stackoverflow.com/a/46181/28324, with
	// minor changes.
	email:
		'(([^<>\\(\\)\\[\\]\\\\.,;:\\s@"]+(\\.[^<>\\(\\)\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]\\{1,3\\}\\.[0-9]\\{1,3\\}\\.[0-9]\\{1,3\\}\\.[0-9]\\{1,3\\}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))',
	fullname: '\\s*[^\\d\\s]{2,}\\s+([^\\d\\s]+\\s+)*[^\\d\\s]{2,}\\s*',
	name: `^\\s*([^\\d\\s]{2,})+\\s*`,
	middleInitial: '(?:[a-zA-Z]|^)$',
	phone: '^(\\+\\d{1,2}\\s)?\\(?[2-9]\\d{2}\\)?[\\s.\\-]?\\d{3}[\\s.\\-]?\\d{4}$',
};

export const takeOverDealerCTAs = ($CTAs, handler, ctaType, vin) => {
	($CTAs || []).forEach(($cta) => {
		if (document.body.contains($cta)) {
			let $newCta = $cta;
			if ($cta.classList.contains('autofi-cta-clone') && $cta.getAttribute('data-autofi-cta-vin') !== vin) {
				// Clone CTA to obtain identical CTA without dealer's event handler, which
				// we don't want to run, using the trick described at
				// https://stackoverflow.com/a/4386514/28324
				$newCta = $cta.cloneNode(true);
				$cta.replaceWith($newCta);
			}

			$newCta.onclick = null;
			$newCta.addEventListener('click', handler);
			$newCta.classList.add('autofi-taken-over-cta');
			$newCta.setAttribute('data-autofi-cta-type', ctaType);
			$newCta.setAttribute('data-autofi-cta-vin', vin);
		}
	});
};

/**
 * @param {string} pathway Pathways enum or string matching path to pathway setting in models
 * @param {object} websiteSettings the dealer settings aka dealer.websiteSettings
 * @param {string} pageType 'listings', 'details', or 'other'
 * @param {object} sessionData
 * @param {sessionData.isInStore} boolean
 * @returns {boolean} whether the given pathway is enabled on the page
 */
export const isPathwayEnabled = (pathway, websiteSettings, pageType, { isInStore }) => {
	// NOTE: pathwayString does not match pathway enum, because it must match
	// path to toggle setting, e.g. websiteSettings.ui.details.testDrive.enabled

	const pathwayString = get(
		{
			[Pathways.ExplorePayments]: 'explorePayments',
			[Pathways.RequestMoreInfo]: 'requestMoreInfo',
			[Pathways.TestDrive]: 'testDrive',
			[Pathways.UnlockPricing]: 'explorePayments',
			[Pathways.CreditApp]: 'creditApp',
			[Pathways.PrivateOffers]: 'privateOffers',
		},
		pathway,
		pathway
	);

	/**
	 * @todo
	 * We should get away from pageType, there's little benefit
	 */
	// default to VDP settings for API consumers
	const pageTypeFeatures = websiteSettings.ui[pageType === 'api' ? 'details' : pageType];
	let pathwayKey = pathwayString;

	if (pathwayKey === 'explorePayments') {
		const userLocation = isInStore ? 'inStore' : 'outOfStore';
		pathwayKey = `${pathwayKey}.${userLocation}`;
	}

	return get(pageTypeFeatures, `pathways.${pathwayKey}.enabled`, true);
};

/**
 * @param {object} dealer
 * @param {string} pathwayType one of the pathway types
 * @param {HTMLElement} eventTarget
 * @param {[object]} opts
 * @param {[string]} opts.formHeader override for pathways API
 * @param {[string]} opts.formSubheader override for pathways API
 * @returns {object} custom title & subtitle string texts
 */
export const getPathwayHeaderTexts = (dealer, pathwayType, eventTarget, opts = {}) => {
	const defaultTitle = getDefaultPathwayTitleText(pathwayType, dealer);
	const defaultSubTitle = getDefaultPathwaySubTitleText(pathwayType, dealer);

	let titleI18n;
	let legacyTitle;
	let subTitleI18n;
	let legacySubtitle;
	if (eventTarget) {
		const selector =
			'[data-autofi-pathway-title], [data-autofi-pathway-title-i18n], ' +
			'[data-autofi-pathway-sub-title], [data-autofi-pathway-sub-title-i18n]';
		let $el = eventTarget.closest(selector);
		if (!$el) {
			const shadowRootHost = get(eventTarget.getRootNode(), 'host');
			if (shadowRootHost) {
				$el = shadowRootHost.closest(selector);
			}
		}
		const dataset = $el && $el.dataset ? $el.dataset : {};
		const { autofiPathwayTitleI18n, autofiPathwaySubTitleI18n } = dataset;
		({ autofiPathwayTitle: legacyTitle, autofiPathwaySubTitle: legacySubtitle } = dataset);
		titleI18n = autofiPathwayTitleI18n ? JSON.parse(autofiPathwayTitleI18n) : undefined;
		subTitleI18n = autofiPathwaySubTitleI18n ? JSON.parse(autofiPathwaySubTitleI18n) : undefined;
	}

	// TODO: always return string that can be directly used instead of
	// sometimes returning key that must be looked up in dictionary.

	return {
		currentTitle: opts.formHeader || getI18nString(dealer, titleI18n) || legacyTitle || defaultTitle,
		currentSubTitle: opts.formSubheader || getI18nString(dealer, subTitleI18n) || legacySubtitle || defaultSubTitle,
	};
};

export const getDefaultPathwayTitleText = (pathwayType, dealer) => {
	const locale = dealer.settings.preferredLanguage;
	return {
		[Pathways.ExplorePayments]: 'who-are-we-giving-this-deal-to',
		[Pathways.CreditApp]: 'see-personalized-estimates',
		[Pathways.Deposit]: 'deposit-required',
		[Pathways.RequestMoreInfo]: 'request-more-information',
		[Pathways.TestDrive]: 'schedule-a-test-drive',
		[Pathways.TradeIn]: localize('one-more-step', {}, locale),
		[Pathways.UnlockPricing]: 'unlock-your-online-deal',
	}[pathwayType];
};

export const getDefaultPathwaySubTitleText = (pathwayType, dealer) => {
	const locale = dealer.settings.preferredLanguage;
	return {
		[Pathways.ExplorePayments]: 'price-and-payment-options',
		[Pathways.UnlockPricing]: 'price-and-payment-options',
		/**
		 * we are using a string, instead of just a key in the dictionary file,
		 * because it is being sent to loanapp which will use it directly,
		 * without being able to look it up.
		 */
		[Pathways.TradeIn]: localize('contact-info', {}, locale),
	}[pathwayType];
};

export const prettyTimeString = (hour, minute) => {
	const dummyDate = new Date();
	dummyDate.setHours(hour);
	dummyDate.setMinutes(minute);
	return dateFormat(dummyDate, 'h:mm a');
};

export const parseMoneyString = (moneyString) => {
	const pureMoneyString = moneyString.replace(/^\$/, '').replace(/,/g, '');
	return parseFloat(pureMoneyString).toFixed(0);
};

export const parseTimeString = (timeString) => {
	const pieces = timeString.match(/^(\d+)(?::(\d+))?\s*(am|pm)?$/i);
	if (pieces) {
		const [hourString, minuteString, amOrPm] = drop(pieces, 1);
		let hour = parseInt(hourString, 10);
		if (amOrPm) {
			hour = (hour % 12) + (/pm/i.test(amOrPm) ? 12 : 0);
		}
		const minute = parseInt(minuteString, 10) || 0;
		return [hour, minute];
	} else {
		return [undefined, undefined];
	}
};

export const getI18nString = (dealer, i18nSettingMap) => {
	const pageLanguage = getLanguageTag(dealer);
	const locale = pageLanguage || dealer.settings.preferredLanguage || 'en';
	return get(i18nSettingMap, locale) || get(i18nSettingMap, languageFromLocale(locale)) || '';
};

export const languageFromLocale = (locale) => locale.replace(/-.*$/, '');

export const hasPrivateOffers = (privateOffers) => {
	return Boolean(privateOffers && privateOffers.length);
};

export const isProductionTestUser = (email) => {
	return ProductionTestUserEmails.includes(email);
};

export const isTestEmail = (email) => {
	return /@autofi\.(?:com|io)$/.test(email) || isProductionTestUser(email);
};

export const isTestName = (name) => {
	return TestAppNames.includes(name);
};

export const isTestUser = (applicant = {}) => {
	return isTestEmail(applicant.email) || isTestName(get(applicant, 'name.full', ''));
};

export const isAggregator = (dealer = {}) =>
	get(dealer, 'source', 'AutoFi') !== 'AutoFi' && get(dealer, 'settings.isGroupSite', false);

export const vehicleHasValidPricing = (vehicle) => {
	const { msrp, salePrice } = vehicle;
	// eslint-disable-next-line no-restricted-globals
	const msrpIsValid = typeof msrp === 'number' && isFinite(msrp) && msrp > 0;
	const salePriceIsValid = !('salePrice' in vehicle && isNil(salePrice));
	return msrpIsValid && salePriceIsValid;
};

export const shouldShowDealMaker = (vehicle, currentUrl) => {
	const normalizedQuery = parseQuery(currentUrl);
	const afEnable = normalizedQuery['af-enable'] === 'true';
	const vehicleConstraints = get(vehicle.dealer, 'websiteSettings.ui.vehicleConstraints');
	const hasMatchingConstraint = Boolean(matchingConstraint(vehicle, vehicleConstraints));
	return (
		vehicle.dealer && (vehicle.dealer.isLive || afEnable) && hasMatchingConstraint && vehicleHasValidPricing(vehicle)
	);
};

export const shouldRenderForVehicle = (vehicle, currentDealer, currentUrl) => {
	const normalizedQuery = parseQuery(currentUrl);
	const afEnable = normalizedQuery['af-enable'] === 'true';
	const rooftopDealerAllowsVehicle = shouldShowDealMaker(vehicle, currentUrl);

	return (currentDealer.isLive || afEnable) && (currentDealer.alwaysShowLeadgen || rooftopDealerAllowsVehicle);
};

export const vehicleIsValidForEstimator = (vehicle) => {
	return Boolean(vehicle && ['dealerRetailPrice', 'make', 'year', 'msrp', 'age'].every((x) => !isNil(vehicle[x])));
};

export const placementUsesDdcApi = (placement) => placement.startsWith('ddc_');

export const ddcApiLocationFromPlacement = (placement) => (placement.match(/^ddc_(?!intent_)(.*)$/) || [])[1];

export const ddcApiIntentFromPlacement = (placement) => (placement.match(/^ddc_intent_(.*)$/) || [])[1];

export const ctaTypeDefaultTextIdMap = {
	creditApp: 'get-pre-approved',
	deposit: 'hold-this-vehicle',
	privateOffers: 'check-your-private-offer',
	requestMoreInfo: 'request-more-info',
	standAloneCreditApp: 'start-your-credit-application',
	startApplication: 'explore-payments',
	testDrive: 'schedule-test-drive',
	tradeInWithVin: 'get-your-trade-in-value',
};

/**
 * @param {string} value
 */
export const hasNoNumbers = (value) => {
	return !/\d/.test(value);
};

export const shouldLog = () => {
	const logParams = new URL(window.location.href).searchParams.getAll('af-log');
	return logParams.some((param) => ['enabled', 'true', 'yes', '1'].includes(param.toLowerCase()));
};

export const logToConsole = (...args) => {
	if (shouldLog()) {
		// eslint-disable-next-line no-console
		console.log(...args);
	}
};

export const warnToConsole = (...args) => {
	if (shouldLog()) {
		// eslint-disable-next-line no-console
		console.warn(...args);
	}
};

export const errorToConsole = (...args) => {
	if (shouldLog()) {
		// eslint-disable-next-line no-console
		console.error(...args);
	}
};

export const loadStripeCheckoutForm = (payload, onError) => {
	const { dealerId, loanAppBaseUrl, loanAppId, token, vehicleData } = payload;
	HTTPClient.post(
		{
			payload: {
				currentUrl: window.location.href,
				dealerId,
				isLaunchedByCta: true,
				loanAppId,
				token,
				vehicleData,
			},
			endpoint: { url: `${loanAppBaseUrl}/api/v1/create-checkout-session` },
		},
		async (error, response) => {
			if (error) {
				alert(error);
				if (onError) {
					onError();
				}
				return;
			}
			const respObject = JSON.parse(response);
			const { connectedAccountId, session, publicKey } = respObject;

			const stripePromise = loadStripe(publicKey, {
				stripeAccount: connectedAccountId,
			});
			const stripe = await stripePromise;
			const result = await stripe.redirectToCheckout({
				sessionId: session.id,
			});

			if (result.error) {
				// eslint-disable-next-line no-console
				console.error(result.error.message);
			}
		}
	);
};

export const getDiscountsAndRebatesAmount = (vehicle, estimate) =>
	vehicle ? sumBy(vehicle.discounts, 'amount') + get(estimate, 'totalRebates', 0) : 0;

const envMap = {
	'https://checkout.autofi.com/script.js': 'prod',
	'https://panda.prod.core.autofi.io/script.js': 'prod',
	'https://checkout-uat.autofi.com/script.js': 'uat',
	'http://checkout-staging.autofi.com/script.js': 'stage',
	'http://checkout-qa.autofi.com/script.js': 'qa',
	'http://panda:3003/script.js': 'local',
	// there is some concern with including such a generic script src
	// if a third party dev included this script as well as prod panda script
	// 'http://localhost:3003/script.js': 'local'
};

/**
 * Attempt to determine the environment based on host or current website.
 * Most cases we should use PROD but if host name matches our development hostname standards
 * we can go direct to the current environment
 * @param {string} host
 */
export const getCurrentEnv = () => {
	const pandaPrRegex = /panda-staging-pr-[0-9]+\.pr\.core\.autofi\.io/;

	const scripts = document.getElementsByTagName('script');
	for (let script of scripts) {
		const url = script.src;
		if (envMap[url]) {
			return envMap[url];
		}

		if (pandaPrRegex.test(url)) {
			return 'stage';
		}

		// test script and attempt to extract env from url
		const match = url.match(/https?:\/\/panda\.([a-z0-9]+)\.core\.autofi\.io\/script\.js/);
		if (match) {
			return match[1];
		}
	}

	// default to prod
	return 'prod';
};

export const getMFAClientEntryPoint = () => {
	const normalizedQuery = parseQuery(window.location.href);
	const pandaEnv = getCurrentEnv();
	const mfaClientOverride = normalizedQuery['af-mfa-client'];

	if (mfaClientOverride && mfaClientOverride.includes('.pr')) {
		return `https://consumer-mfa-client-${mfaClientOverride}.core.autofi.io`;
	}

	const overrideDomain = {
		local: 'http://localhost:3051',
		stage: 'https://cdn.stage.core.autofi.io/mfa-client',
		staging: 'https://cdn.stage.core.autofi.io/mfa-client',
		uat: 'https://cdn.uat.core.autofi.io/mfa-client',
		qa: 'https://cdn.qa.core.autofi.io/mfa-client',
		prod: 'https://cdn.prod.core.autofi.io/mfa-client',
	}[normalizedQuery['af-mfa-client'] ?? normalizedQuery['af-env'] ?? pandaEnv];

	return overrideDomain;
};

export const getPandaEndpoint = () => {
	try {
		const overrideScript = document.querySelector('#autofi_override_script');

		if (overrideScript) {
			return new URL(overrideScript.src).origin;
		}

		const pandaPrRegex = /panda-staging-pr-[0-9]+\.pr\.core\.autofi\.io/;
		const matchRegex = /https?:\/\/panda\.([a-z0-9]+)\.core\.autofi\.io\/script\.js/;

		const scripts = document.getElementsByTagName('script');

		for (let script of scripts) {
			const url = script.src;

			if (envMap[url] || pandaPrRegex.test(url) || matchRegex.test(url)) {
				return new URL(url).origin;
			}
		}

		throw new Error('No Panda script found');
	} catch (e) {
		// default to prod
		return 'https://checkout.autofi.com';
	}
};

/**	Determines which url to use for prequalify iframes
 * TODO: Update with actual Prequal App info
 */
export function getPrequalifyOrigin() {
	const normalizedQuery = parseQuery(window.location.href);
	const pandaEnv = getCurrentEnv();

	const prNumber = +normalizedQuery['af-prequal-pr'];

	if (Number.isFinite(prNumber)) {
		return `https://prequal-pr-${prNumber}.pr.core.autofi.io`;
	}

	const overrideDomain = {
		dev: 'https://prequal.dev.core.autofi.io',
		env1: 'https://prequal.env1.core.autofi.io',
		local: 'http://localhost:3022',
		perf: 'https://prequal.perf.core.autofi.io',
		prod: 'https://prequal.prod.core.autofi.io',
		qa: 'https://prequal.qa.core.autofi.io',
		stage: 'https://prequal.stage.core.autofi.io',
		staging: 'https://prequal.stage.core.autofi.io',
		uat: 'https://prequal.uat.core.autofi.io',
	}[normalizedQuery['af-prequal-env'] ?? normalizedQuery['af-env'] ?? pandaEnv];

	return overrideDomain;
}

/**	Determines which url to use for Drive Together iframes */
export function getDriveTogetherOrigin() {
	const normalizedQuery = parseQuery(window.location.href);
	const pandaEnv = getCurrentEnv();

	const prNumber = +normalizedQuery['af-drive-pr'];

	if (Number.isFinite(prNumber)) {
		return `https://consumer-staging-pr-${prNumber}.pr.core.autofi.io`;
	}

	const overrideDomain = {
		prod: 'https://drive.us',
		uat: 'https://consumer-uat.scusa-apps.autofi.io',
		stage: 'https://drive-stage.santanderconsumerusa.com',
		staging: 'https://drive-stage.santanderconsumerusa.com',
		perf: 'https://drive-performance.santanderconsumerusa.com',
		qa: 'https://drive-qa.santanderconsumerusa.com',
		env1: 'https://consumer-env1.scusa-apps.autofi.io',
		hotfix: 'https://drive-hotfix.santanderconsumerusa.com',
		env2: 'https://drive-hotfix.santanderconsumerusa.com',
		sandbox: 'https://drive-test.santanderconsumerusa.com',
		env3: 'https://drive-test.santanderconsumerusa.com',
		dev: 'https://consumer-dev.scusa-apps.autofi.io',
		local: 'http://localhost:3020',
	}[normalizedQuery['af-drive-env'] ?? normalizedQuery['af-env'] ?? pandaEnv];

	return overrideDomain;
}

export const ctaTypeFromPathwayName = (rawPathwayName) =>
	({
		creditapp: 'creditApp',
		deposit: 'deposit',
		drivetogether: 'driveTogether',
		explorepayments: 'startApplication',
		prequalify: 'prequalify',
		privateoffers: 'privateOffers',
		requestmoreinfo: 'requestMoreInfo',
		scheduletestdrive: 'testDrive',
		standalonecreditapp: 'standAloneCreditApp',
		tradein: 'tradeInWithVin',
	}[trimmedLowerCase(rawPathwayName ?? '')]);
