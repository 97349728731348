import { createPortal } from 'preact/compat';
import { h } from 'preact';
import { getDriveTogetherOrigin } from '../utils';
import isolate from '~ui/components/isolate';
import withStyles from '~ui/components/withStyles';
import styles from './driveTogetherOrchestrator.styl';

function DriveTogetherOrchestratorIframe({ vehiclesByVin, driveDealer, featureFlags }) {
	const origin = getDriveTogetherOrigin();
	const vehicles = Object.values(vehiclesByVin);
	const allVehiclesForDT = vehicles.filter((vehicle) =>
		vehicle.ui.autofiCtaStuff.some((ctaDetails) => ctaDetails.ctaType === 'driveTogether')
	);

	if (!allVehiclesForDT.length || !featureFlags?.driveTogether) {
		return null;
	}

	const vins = allVehiclesForDT.map((vehicle) => vehicle.vin);

	return (
		<iframe
			class="drive-together-orchestrator"
			title={`drive-together-orchestrator`}
			src={`${origin}/drive-together/${driveDealer.code}/${vins.join(',')}/orchestrator`}
		/>
	);
}
const DriveTogetherOrchestratorIframeStyled = isolate(withStyles(DriveTogetherOrchestratorIframe, styles));

export default function DriveTogetherOrchestrator(props) {
	return createPortal(<DriveTogetherOrchestratorIframeStyled {...props} />, document.body);
}
