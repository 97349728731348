import pickBy from 'lodash/pickBy';
import { init as moduleFederationRuntimeInit } from '@module-federation/enhanced/runtime';

import * as amplitude from '~ui/utils/amplitude';
import { MainUI } from '~ui/mainUI/mainUI';
import consumerSession from '~ui/utils/consumerSession';
import HTTPClient from '~ui/utils/HTTPClient';
import parseQuery from '~lib/parse-query';
import Scraper from '~lib/scraper/scraper';
import { getPandaEndpoint, getMFAClientEntryPoint } from '~ui/utils';

export const getOverrideDomain = () => {
	const normalizedQuery = parseQuery(window.location.href);
	let env = normalizedQuery['af-env'];

	const prNumber = +(normalizedQuery['af-pr'] ?? normalizedQuery['af-panda-pr']);
	if (Number.isFinite(prNumber)) {
		return `https://panda-staging-pr-${prNumber}.pr.core.autofi.io`;
	}

	if (!env) {
		return undefined;
	}

	// support env1, env2, env3, etc
	if (env.startsWith('env')) {
		return `https://panda.${env}.core.autofi.io`;
	}

	return {
		prod: 'https://checkout.autofi.com',
		uat: 'https://checkout-uat.autofi.com',
		stage: 'https://checkout-staging.autofi.com',
		staging: 'https://checkout-staging.autofi.com',
		qa: 'https://checkout-qa.autofi.com',
		local: 'http://localhost:3003',
	}[env];
};

const normalizedQuery = parseQuery(window.location.href);

const injectOverrideScript = (url) => {
	const newScript = document.createElement('script');
	newScript.src = url;
	newScript.id = 'autofi_override_script';
	document.body.appendChild(newScript);
};

export const scrapeAndRender = () => {
	if (normalizedQuery['af-disable']) {
		// eslint-disable-next-line no-console
		console.warn('Disabled for this page load.');
		return;
	}
	window.autofi = window.autofi || {};

	const params = pickBy(
		{
			currentUrl: window.location.href,
			'af-pr': normalizedQuery['af-pr'],
			'af-panda-pr': normalizedQuery['af-panda-pr'],
			'af-env': normalizedQuery['af-env'],
		},
		(val) => ![null, undefined].includes(val)
	);

	const autofiDataUrl = `${getPandaEndpoint()}/autofiData?${new URLSearchParams(params)}`;

	HTTPClient.get({ endpoint: { url: autofiDataUrl } }, (error, responseText) => {
		if (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		} else {
			const response = JSON.parse(responseText);
			const { autofiData, error } = response;

			window.autofi.data = autofiData;

			if (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
			if (!autofiData) {
				// eslint-disable-next-line no-console
				console.error('autofiData not found');
				return;
			}

			const { loanAppBaseUrl, trackerConfigs } = autofiData;
			amplitude.init({ loanAppBaseUrl, ...trackerConfigs });
			consumerSession.createSession(autofiData, () => {
				const ui = new MainUI();
				const scraper = new Scraper(ui, autofiData);

				window.autofi.scraper = scraper;

				scraper.run();
			});
		}
	});
};

const prepareToScrape = () => {
	if (document.querySelector('#autofi_override_script')) {
		scrapeAndRender();
	} else {
		const domain = getOverrideDomain();
		if (domain) {
			const overrideUrl = `${domain}/script.js`;
			injectOverrideScript(overrideUrl);
		} else {
			scrapeAndRender();
		}
	}
};

prepareToScrape();

moduleFederationRuntimeInit({
	name: 'panda-host',
	remotes: [
		{
			name: 'mfaApp',
			entry: `${getMFAClientEntryPoint()}/remoteEntry.js`,
		},
	],
});
